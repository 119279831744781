const DashboardTypes = {
    SERVICE_REQUESTS_REQUEST: "SERVICE_REQUESTS_REQUEST",
    SERVICE_REQUESTS_SUCCESS: "SERVICE_REQUESTS_SUCCESS",
    TOP_TECHNICIANS_REQUEST: "TOP_TECHNICIANS_REQUEST",
    TOP_TECHNICIANS_SUCCESS: "TOP_TECHNICIANS_SUCCESS",
    ACCOUNT_BREAKDOWN_REQUEST: "ACCOUNT_BREAKDOWN_REQUEST",
    ACCOUNT_BREAKDOWN_SUCCESS: "ACCOUNT_BREAKDOWN_SUCCESS",
    DEVICE_INSTALLS_REQUEST: "DEVICE_INSTALLS_REQUEST",
    DEVICE_INSTALLS_SUCCESS: "DEVICE_INSTALLS_SUCCESS",
    SERVICES_SCHEDULED_REQUEST: "SERVICES_SCHEDULED_REQUEST",
    SERVICES_SCHEDULED_SUCCESS: "SERVICES_SCHEDULED_SUCCESS",
};

export { DashboardTypes };
