import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Grid } from "@mui/material";
import * as Icon from '@mui/icons-material';
import Header from "../Header/Header";
import Button from "../../Shared/Button";
import TechnicianTable from "./TechnicianTable";
import AddModal from "../../Shared/AddModal";
import { TechnicianTypes } from "../../redux/action_types/technician_types";
import Search from "../../Shared/Search";
import { cloneDeep, debounce } from "lodash";
import Checkbox from "../../Shared/Checkbox";
import Rating from "../../Shared/Rating";
import Pagination from "../../Shared/Pagination";

/**
 * Returning required redux state data
 * @param {Object} state - Redux state
 */
const getReduxData = (state) => {
    return {
        TechnicianData: state.Technician
    };
};
const Technician = () => {
    const dispatch = useDispatch();
    const data = useSelector(getReduxData);
    const [state, setState] = useState({
        openModal: false,
        limit: 10,
        page: 1,
        sortField: "firstName",
        sortOrder: "asc",
        searchText: '',
        assignedStatus: [],
        rating: [],
    });
    const debounceLoadData = useCallback(debounce(getTechnicians, 500), []);

    const addTechnicianFn = () => {
        setState({ ...state, openModal: true })
    }

    const handleCloseModal = (val) => {
        setState({ ...state, openModal: false })
        if (val) {
            getTechnicians(state);
        }
    }


    useEffect(() => {
        if (state.searchText) {
            debounceLoadData(state);
        } else {
            getTechnicians(state);
        }
    }, [dispatch, state.rating, state.assignedStatus, state.searchText, state.page, state.sortField, state.sortOrder]);

    function getTechnicians(data) {
        const paramsObj = cloneDeep(data);
        delete paramsObj['openModal'];
        delete paramsObj['data'];
        paramsObj['searchText'] = data.searchText;
        dispatch({
            type: TechnicianTypes.TECHNICIAN_LIST_REQUEST,
            params: paramsObj
        })
    }

    const setSearchData = (data) => {
        setState({ ...state, data })
    }

    const sortTechnician = (sortField, sortOrder) => {
        setState({ ...state, sortField: sortField, sortOrder: sortOrder })
    }

    const setPageNumber = (pageNo) => {
        setState({...state, page: pageNo })
    }

    const setCheckBoxData = (value, keyName, listName) => {
        let getSelectedList = cloneDeep(state[listName]);
        if (value) {
            getSelectedList.push(keyName)
        } else {
            getSelectedList = getSelectedList.filter((e) => e !== keyName);
        }
        setState({ ...state, [listName]: getSelectedList })
    }
    
    const totalCount = data.TechnicianData.technicianList.total;
    const totalPages = Math.ceil(totalCount / state.limit)
    return (
        <Container className="main">
            <Header />
            <Box className="ph3">
                <h2 className="title mt3">TECHNICIANS</h2>
                <Grid container spacing={1.5}>
                    <Grid item xs={12} md={2.2}>
                        <Button text={"ADD TECHNICIAN"} btnClick={addTechnicianFn} className="primary-btn full-width" icon={<Icon.Add className='fntLg mr0-5' />} />
                        <Box className="mt2">
                            <Search keyName={"searchText"} data={state} setSearchData={setSearchData} />
                            <div className="full-border-grey ph1 ">
                                <div className=" border-bottom-grey mv1">
                                    <div>ASSIGNED STATUS</div>
                                    <div>
                                        <Checkbox keyName={"ASSIGNED"} listName={"assignedStatus"} isChecked={state['assignedStatus'].indexOf("ASSIGNED") > -1} setCheckBoxData={setCheckBoxData} /><span className="fntSm">Assigned</span>
                                    </div>
                                    <div>
                                        <Checkbox size='small' keyName={"NOTASSIGNED"} listName={"assignedStatus"} isChecked={state['assignedStatus'].indexOf("NOTASSIGNED") > -1} setCheckBoxData={setCheckBoxData} /><span className="fntSm">Not Assigned</span>
                                    </div>
                                </div>
                                <div>
                                    <div>Rating</div>
                                    {[5, 4, 3, 2, 1].map((each, idx) => {
                                        return (
                                            <div className="flx align-center" key={idx}>
                                                <Checkbox size='small' keyName={each} listName={"rating"} isChecked={state['rating'].indexOf(each) > -1} setCheckBoxData={setCheckBoxData} /><span className="fntSm"><Rating rate={each} height='22' /></span>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={9.8} className="pl3">
                        <TechnicianTable techniciansList={data.TechnicianData.technicianList} sortTechnician={sortTechnician}/>
                        {
                            totalCount > 1 &&
                            <Pagination count={totalPages} setPageNumber={setPageNumber} currentPage={state.page} />
                        }
                    </Grid>
                </Grid>
            </Box>
            {
                state.openModal &&
                    <AddModal multiple={false} openModal={state.openModal} handleCloseModal={handleCloseModal} title={'Add a Technician'} btnText={'ADD TECHNICIAN'} />
            }
        </Container>
    )
}

export default Technician;