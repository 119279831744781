import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CustomerTypes } from "../../redux/action_types/customer_types";
import { TechnicianTypes } from "../../redux/action_types/technician_types";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import * as UI from "@mui/material";
import * as Icon from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import Header from "../Header/Header";
import PP_Blank from "../../images/PP_Blank.png";
import BP_Blank from "../../images/BuildingPic.jpeg";
import Rating from "../../Shared/Rating";
import Button from "../../Shared/Button";
import AddModal from "../../Shared/AddModal";
import UploadInvoiceModal from "../../Shared/UploadInvoiceModal";
import Input from "../../Shared/Input";
import { getFullName } from "../../Shared/Utils";
import ModalCustomerDetails from "./Modal-CustomerDetails";
import ModalScheduleService from "./modalScheduleService";
import ModalEditService from "./modalEditService";
import moment from "moment";
import PdfViewer from "../../Shared/PdfViewer";
import ModalRescheduleService from "../Customers/modalRescheduleService";

/**
 * Returning required redux state data
 * @param {Object} state - Redux state
 */
export const getReduxData = (state) => {
  return {
    CustomerData: state.Customer,
    TechnicianData: state.Technician,
  };
};
const CustomerDetails = () => {
  const dispatch = useDispatch();
  const data = useSelector(getReduxData);
  const urlParams = useParams();
  const [state, setState] = useState({
    openModal: false,
    fileUploadError: "",
    toggleMenu: "serviceHistory",
    searchKey: "",
    pdfFile: "",
    openServiceModal: false,
    openScheduleService: false,
    openInvoiceModal: false,
    openEditService: false,
    modalData: {
      appointment: "",
      address: "",
      customerNotes: "",
      serviceNotes: "",
      technicainPic: "",
      technicianName: "",
      technicainRating: 0,
    },
    scheduleServiceData: {
      customerNotes: "",
      address: "",
      requestId: "",
      addressId: "",
    },
    editServiceData: {
      customerNotes: "",
      address: "",
      requestId: "",
      addressId: "",
      scheduledTime: "",
      technician: {},
    },
  });

  const setSearch = (data) => {
    setState({ ...state, searchKey: data.searchKey });
  };

  const openPdf = (file) => () => {
    setState({ ...state, pdfFile: file });
  };

  const updateRequestStatus = (statusString) => {
    setState((prevState) => {
      var a = {
        ...prevState,
        scheduleServiceData: {
          ...prevState.scheduleServiceData,
          requestStatus: statusString,
        },
      };
      return a;
    });
  };

  const handleCloseModal = (val) => {
    setState({
      ...state,
      openModal: false,
      openServiceModal: false,
      openScheduleService: false,
      openReScheduleService: false,
      openEditService: false,
      openInvoiceModal: false,
    });
    if (val) {
      getCustomerDetails();
    }
  };

  const handleCloseOnCancel = (statusString) => {
    setState({
      ...state,
      openModal: false,
      openServiceModal: false,
      openScheduleService: false,
      openReScheduleService: false,
      openEditService: false,
      openInvoiceModal: false,
      scheduleServiceData: {
        ...state.scheduleServiceData,
        requestStatus: statusString,
      },
    });
    addressData.requestStatus = statusString;
  };

  const handleRescheduleService = () => {
    setState((prvState) => {
      return {
        ...prvState,
        openEditService: false,
        openReScheduleService: true,
      };
    });
  };

  const openServiceModal = (servicedata) => {
    let modalData = state.modalData;
    modalData.appointment = moment(servicedata.scheduledTime).format(
      "MMM Do, hh:mm a"
    );
    modalData.address = addressData.address;
    modalData.customerNotes = servicedata.additionalComments;
    modalData.serviceNotes = servicedata.serviceNotes;
    modalData.ratingComments = servicedata.ratingComments;
    modalData.technicainPic = servicedata.technician.profilePic;
    modalData.technicianName =
      servicedata.technician.firstName + " " + servicedata.technician.lastName;
    modalData.technicainRating = servicedata.rating;
    setState({ ...state, modalData: modalData, openServiceModal: true });
  };

  const openScheduleService = () => {
    let modalData = state.scheduleServiceData;
    modalData.address = addressData.address;
    modalData.addressId = addressData.id;
    modalData.requestId =
      requested && requested.length > 0 ? requested[0].id : "";
    modalData.customerNotes =
      requested && requested.length > 0 ? requested[0].additionalComments : "";
    setState({
      ...state,
      openScheduleService: true,
      scheduleServiceData: modalData,
    });
  };

  const openEditService = () => {
    let modalData = state.scheduleServiceData;
    modalData.address = addressData.address;
    modalData.requestStatus = addressData.requestStatus;
    modalData.addressId = addressData.id;
    modalData.requestId =
      scheduled && scheduled.length > 0 ? scheduled[0].id : "";
    modalData.customerNotes =
      scheduled && scheduled.length > 0 ? scheduled[0].additionalComments : "";
    modalData.scheduledTime =
      scheduled && scheduled.length > 0 ? scheduled[0].scheduledTime : {};
    modalData.technician =
      scheduled && scheduled.length > 0 ? scheduled[0].technician : {};
    setState({ ...state, openEditService: true, editServiceData: modalData });
  };

  useEffect(() => {
    getCustomerDetails();
    dispatch({
      type: TechnicianTypes.TECHNICIANS_WITH_REQUESTS_REQUEST,
    });
  }, [dispatch, state.editServiceData.requestStatus]);

  const getCustomerDetails = () => {
    dispatch({
      type: CustomerTypes.GET_CUSTOMER_REQUEST,
      params: urlParams.id,
    });
  };

  useEffect(() => {
    return () => {
      dispatch({
        type: CustomerTypes.GET_CUSTOMER_SUCCESS,
        data: {
          customerDetails: {},
          addressData: {},
          requested: [],
          completed: [],
          scheduled: [],
          invoices: [],
        },
      });
      dispatch({
        type: TechnicianTypes.TECHNICIANS_WITH_REQUESTS_SUCCESS,
        data: {
          technicians: [],
        },
      });
    };
  }, []);

  const handleTogleChange = (e) => {
    setState({ ...state, toggleMenu: [e.target.value] });
  };

  const {
    customerDetails,
    requested,
    addressData,
    completed,
    scheduled,
    invoices,
  } = data.CustomerData.customerDetails;
  const { technicians } = data.TechnicianData.techniciansWithRequests;
  const afterSearch = invoices.filter((each) =>
    each.name.match(state.searchKey)
  );

  const editCustomerFn = () => {
    setState({ ...state, openModal: true });
  };

  return (
    <Container className="main">
      <Header />
      <Box className="ph3 mb5">
        <Grid container spacing={1.5}>
          <Grid item xs={12} mg={12} lg={6}>
            <Link href="/Customer" className="mb1" underline="hover">
              <ArrowBackIosNewIcon className="fntXXSm fntGreen" />
              <span className="fntSm fntGreen">CUSTOMERS</span>
            </Link>
            <div className=" border-bottom-grey p1">
              <div>
                <img
                  alt="Customer"
                  className="ImgRect-300_500 p1"
                  src={
                    customerDetails.profilePic
                      ? customerDetails.profilePic
                      : BP_Blank
                  }
                />
              </div>
              <Typography className="title fntXXLg">
                {addressData.address}
              </Typography>
              <Typography className="title fntXXLg">
                {addressData.state} {addressData.country} {addressData.zipcode}
              </Typography>
            </div>
            <div className=" border-bottom-grey p1">
              <Typography className="fntGrey ph0-5 fntSm">
                LOCATION INFORMATION{" "}
              </Typography>
              <div className="justify-between flx mr5 fntSm pt0-5">
                <div>Customer Name </div>
                <div>{getFullName(customerDetails)}</div>
              </div>
              <div className="justify-between flx mr5 fntSm pt0-5">
                <div>Email </div>
                <div>{customerDetails.email}</div>
              </div>
              <div className="justify-between flx mr5 fntSm pt0-5">
                <div>Customer Phone#</div>
                <div>{customerDetails.phoneNumber}</div>
              </div>
              <div className="justify-between flx mr5 fntSm pt0-5">
                <div>State / Zip </div>
                <div>
                  {addressData.state} {addressData.zipcode}
                </div>
              </div>
              <div className="justify-between flx mr5 fntSm pt0-5">
                <div>APN </div>
                <div>{addressData.apnNumber}</div>
              </div>
              <div className="justify-between flx mr5 fntSm pt0-5 pb3">
                <div>Device Number </div>
                <div>{addressData.deviceNumber}</div>
              </div>
              <Button
                text={"EDIT INFORMATION"}
                btnClick={editCustomerFn}
                className="primary-btn mb1"
                icon={<Icon.EditOutlined className="fntLg mr0-5" />}
              />
            </div>
            <div className=" border-bottom-grey p1 ">
              <Typography className="fntGrey ph0-5 pv1 fntSm">
                SERVICE STATUS{" "}
              </Typography>
              {["REQUESTED", "NO ISSUE", "CANCELLED"].indexOf(
                addressData.requestStatus
              ) > -1 && (
                <div className={`statusBtn ${addressData.requestStatus}`}>
                  {addressData.requestStatus}
                </div>
              )}
              {addressData.requestStatus === "SCHEDULED" &&
                scheduled.length > 0 && (
                  <div
                    className="scheduledStatus full-border-grey"
                    style={{
                      padding: "20px",
                      display: "inline-flex",
                      width: "93%",
                    }}
                  >
                    <div style={{ width: "40%", fontWeight: "bold" }}>
                      {moment(scheduled[0].scheduledTime).format(
                        "MMM Do, YYYY"
                      )}
                    </div>
                    <div style={{ width: "20%" }}>
                      {moment(scheduled[0].scheduledTime).format("hh:mm a")}
                    </div>
                    <div style={{ width: "40%", fontWeight: "bold" }}>
                      {scheduled[0].technician.profilePic ? (
                        <img
                          src={scheduled[0].technician.profilePic}
                          alt="profile-pic"
                          className="mr1 profile-pic-table vam"
                        />
                      ) : (
                        <AccountCircleOutlinedIcon className="mr1 vam" />
                      )}
                      {scheduled[0].technician.firstName +
                        " " +
                        scheduled[0].technician.lastName}
                    </div>
                  </div>
                )}
            </div>
            <div className="p1">
              <Typography className="fntGrey ph0-5 pv1 fntSm">
                LOCATION OPTIONS{" "}
              </Typography>
              <div className="flx">
                {addressData.requestStatus === "REQUESTED" && (
                  <div className="mh1 ml0">
                    <Button
                      text={"SCHEDULE SERVICE"}
                      className="primary-btn mv1-5 mt0"
                      btnClick={() => openScheduleService()}
                      icon={<Icon.DateRange className="fntLg mr0-5" />}
                    />
                  </div>
                )}
                {addressData.requestStatus === "SCHEDULED" && (
                  <div className="mh1 ml0">
                    <Button
                      text={"EDIT SERVICE"}
                      className="primary-btn edit-btn mv1-5 mt0"
                      btnClick={() => openEditService()}
                      icon={<Icon.CancelPresentation className="fntLg mr0-5" />}
                    />
                  </div>
                )}
                <div className="mh1 ml0">
                  <Button
                    text={"UPLOAD A FILE"}
                    className="primary-btn mv1-5 mt0"
                    btnClick={() => {
                      setState({ ...state, openInvoiceModal: true });
                    }}
                    icon={<Icon.Add className="fntLg mr0-5" />}
                  />
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={6} className="pt5 pl3">
            <div className="full-border-grey  ph1 pv0-5">
              <UI.ToggleButtonGroup
                value={state.toggleMenu}
                exclusive
                onChange={handleTogleChange}
                fullWidth
                size="small"
              >
                <UI.ToggleButton value="serviceHistory">
                  Service History
                </UI.ToggleButton>
                <UI.ToggleButton value="files">Files</UI.ToggleButton>
              </UI.ToggleButtonGroup>
            </div>
            {state.toggleMenu == "files" && (
              <div className="pv0-5">
                <Input
                  type="text"
                  icon="search"
                  keyName="searchKey"
                  data={state}
                  className={"small-input"}
                  setData={setSearch}
                />
              </div>
            )}
            {state.toggleMenu == "serviceHistory"
              ? completed.map((each, indx) => {
                  return (
                    <div
                      className="full-border-grey justify-between flx  pv0-3 mv0-5"
                      key={indx}
                      onClick={() => {
                        openServiceModal(each);
                      }}
                    >
                      <div>
                        <Icon.Check className="fntXLg pv0-5 ph1" />
                        <p className="fntMd mh1 address">
                          {moment(each.completedAt).format("MMM Do, YYYY")}
                        </p>
                      </div>
                      <div className="pt0-8">
                        <img
                          alt="Technician"
                          className="avatar-rounded50"
                          src={
                            each.technician.profilePic
                              ? each.technician.profilePic
                              : PP_Blank
                          }
                        />
                        <p className="fntMd mh0-5 username">
                          {each.technician.firstName +
                            " " +
                            each.technician.lastName}
                        </p>
                      </div>
                      <div className="pt0-8">
                        <Rating rate={Math.round(each.rating)} height="22" />
                      </div>
                      <div className="pt0-8">
                        {" "}
                        <Icon.RemoveRedEye className="fntXLg pv0-5 ph1 border-left-grey" />
                      </div>
                    </div>
                  );
                })
              : afterSearch.map((each, indx) => {
                  return (
                    <div
                      className="full-border-grey justify-between flx  pv0-3 mv0-5 cursPoint"
                      key={indx}
                      onClick={openPdf(each)}
                    >
                      <div key={indx} className="flx">
                        <Icon.Description className="fntXLg  pv0-5 ph1" />
                        <div className="fntSm mh1 pt1">{each.name}</div>
                      </div>
                      <div>
                        <div className="fntSm mh3 pt1">
                          Added: {moment(each.invoiceDate).format("MM/DD/YYYY")}
                        </div>
                      </div>
                      <div>
                        {" "}
                        <Icon.RemoveRedEye className="fntXLg pv0-5 ph1 border-left-grey" />
                      </div>
                    </div>
                  );
                })}
          </Grid>
        </Grid>
      </Box>
      {state.pdfFile && (
        <PdfViewer
          pdf={state.pdfFile}
          onClose={() => setState({ ...state, pdfFile: "" })}
        />
      )}
      {state.openModal && (
        <AddModal
          openModal={state.openModal}
          handleCloseModal={handleCloseModal}
          isEdit={true}
          userObj={customerDetails}
          title={"Edit Customer information"}
          btnText={"SAVE CHANGES"}
          type={"customer"}
        />
      )}
      <UploadInvoiceModal
        openModal={state.openInvoiceModal}
        handleCloseModal={handleCloseModal}
        addressObj={addressData}
        completed={completed}
      />
      <ModalCustomerDetails
        openModal={state.openServiceModal}
        handleCloseModal={handleCloseModal}
        data={state.modalData}
      />
      {state.openScheduleService && (
        <ModalScheduleService
          openModal={state.openScheduleService}
          handleCloseModal={handleCloseModal}
          technicians={technicians}
          data={state.scheduleServiceData}
        />
      )}
      {state.openReScheduleService && (
        <ModalRescheduleService
          openModal={state.openReScheduleService}
          handleCloseModal={handleCloseModal}
          data={state.scheduleServiceData}
        />
      )}
      {state.openEditService && (
        <ModalEditService
          openModal={state.openEditService}
          handleCloseModal={handleCloseModal}
          handleCloseOnCancel={handleCloseOnCancel}
          data={state.editServiceData}
          rescheduleService={handleRescheduleService}
          updateRequestStatus={updateRequestStatus}
        />
      )}
    </Container>
  );
};

export default CustomerDetails;
