import React from "react";
import PropTypes from "prop-types";
import { Pagination as PaginationMUI } from '@mui/material';


const Pagination = (props) => {
    const { count, setPageNumber, currentPage } = props;

    const handlePageChange = (event, value) => {
        setPageNumber(value);
    }

    return (
        <PaginationMUI count={count} variant="outlined" shape="rounded" onChange={handlePageChange} page={currentPage} />
    )
}

Pagination.propTypes = {
    count: PropTypes.number,
    currentPage: PropTypes.number,
    setPageNumber: PropTypes.func
};

export default Pagination;