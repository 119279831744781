import { Box, Icon, IconButton, Modal, Typography } from "@mui/material";
import React from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import PP_Blank from "../../images/PP_Blank.png";
import Rating from "../../Shared/Rating";

const ModalCustomerDetails = (props) => {
  const { data, handleCloseModal, openModal } = props;
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
  };
  return (
    <>
      <Modal
        open={openModal}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            className="p1 fntsm mt1"
            variant="h6"
          >
            Service Information
            <IconButton onClick={() => handleCloseModal()} className="right">
              <CancelOutlinedIcon />
            </IconButton>
          </Typography>
          <Typography
            id="modal-modal-description"
            className="flx justify-between bg-green"
            sx={{ mt: 2 }}
          >
            <div className="flx fntWhite p1">
              <div className="br-5 bg-Grey p0-5 mb0-5">
                <CalendarTodayRoundedIcon className="mt0-3" />
              </div>
              <div className="mh1 mt0-5">
                <div className="fntSm">{data.appointment}</div>
                <div className="">{data.address}</div>
              </div>
            </div>
            <div className="fntWhite ph2 mt2">
              <CheckRoundedIcon />
            </div>
          </Typography>
          <Typography
            id="modal-modal-description"
            className="ph2 border-bottom-grey"
          >
            <h5 className="mb0-5">CUSTOMER NOTES</h5>
            <p className="mt0-5" style={{ fontSize: "14px" }}>
              {data.customerNotes}
            </p>
          </Typography>
          <Typography
            id="modal-modal-description"
            className="ph2 border-bottom-grey"
          >
            <h5 className="mb0-5">SERVICE NOTES</h5>
            <p className="mt0-5" style={{ fontSize: "14px" }}>
              {data.serviceNotes}
            </p>
          </Typography>
          <Typography
            id="modal-modal-description"
            className="ph2 border-bottom-grey"
          >
            <h5 className="mb0-5">TECHNICIAN INFORMATION</h5>
            <div className="flx justify-between pb2">
              <div style={{ display: "inline-flex" }}>
                <img
                  alt="Technician"
                  className="avatar-rounded50"
                  src={data.technicainPic ? data.technicainPic : PP_Blank}
                />
                <div style={{ marginLeft: "10px" }}>{data.technicianName}</div>
              </div>
              <div>
                <Rating rate={Math.round(data.technicainRating)} height="22" />
              </div>
            </div>
          </Typography>
          <Typography
            id="modal-modal-description"
            className="ph2 border-bottom-grey"
          >
            <h5 className="mb0-5">COMMENTS</h5>
            <p className="mt0-5" style={{ fontSize: "14px" }}>
              {data.ratingComments}
            </p>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default ModalCustomerDetails;
