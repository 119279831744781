import { CustomerTypes } from "../action_types/customer_types";

const initialState = {
  customerList: {
    addresses: [],
  },
  customerDetails: {
    customerDetails: {},
    addressData: {},
    requested: [],
    completed: [],
    scheduled: [],
    invoices: [],
  },
};

export default function CustomerReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case CustomerTypes.CUSTOMER_LIST_SUCCESS: {
      return {
        ...state,
        customerList: action.data,
      };
    }
    case CustomerTypes.GET_CUSTOMER_SUCCESS: {
      return {
        ...state,
        customerDetails: action.data,
      };
    }
    default:
      return state;
  }
}
