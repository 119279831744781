// Relative Api Paths
const RelativePath = "api/v1";

const URI = {
  LOGIN: `${RelativePath}/web-login`,
  SIGNUP: `${RelativePath}/signup`,
  FORGOT_PASSWORD: `${RelativePath}/forgot-password`,
  RESET_PASSWORD: `${RelativePath}/reset-password`,
  SERVICE_REQUESTS: `${RelativePath}/service-requests`,
  ACCOUNT_BREAKDOWN: `${RelativePath}/account-breakdown`,
  SERVICES_SCHEDULED: `${RelativePath}/scheduled-services`,
  DEVICE_INSTALLS: `${RelativePath}/device-installs`,
  TOP_TECHNICIANS: `${RelativePath}/top-technicians`,
  TECHNICIANS_LIST: `${RelativePath}/technicians`,
  TECHNICIANS_WITH_REQUESTS: `${RelativePath}/technicians-with-requests`,
  GET_TECHNICIAN: `${RelativePath}/technician`,
  ADD_TECHNICIAN: `${RelativePath}/add-technician`,
  UPDATE_TECHNICIAN: `${RelativePath}/update-technician`,
  CUSTOMERS_LIST: `${RelativePath}/addresses`,
  ADD_CUSTOMER: `${RelativePath}/add-customer`,
  EDIT_CUSTOMER: `${RelativePath}/update-customer`,
  GET_CUSTOMER: `${RelativePath}/address`,
  SCHEDULE_SERVICE: `${RelativePath}/schedule-service`,
  DELETE_TECH_DOC: `${RelativePath}/delete-tech-doc/{id}`,
  CANCEL_SERVICE: `${RelativePath}/cancel-service`,
  UPLOAD_INVOICE: `${RelativePath}/upload-invoices`,
};

export { URI };
