import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import { getFullName } from "../../Shared/Utils";

const CustomerTable = (props) => {
    const navigate = useNavigate();
    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F4F5F5',
            color: '#43505C',
            opacity: 0.7,
            border: 0,
            fontSize: 13,
            padding: 10
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            fontWeight: 'bold',
            color: '#43505C'
        },
    }));

    const StyledTableRow = styled(TableRow)(() => ({
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));

    const getCustomerData = (customer) => {
        if (customer.requestStatus === "SCHEDULED") {
            props.modalReschedulefn && props.modalReschedulefn(customer)
        } else {
            navigate(`/Customer/${customer.id}`)
        }
    }

    return (
        <TableContainer component={Paper} style={{ boxShadow: 'none', borderRadius: '0px' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>CUSTOMER NAME</StyledTableCell>
                        <StyledTableCell>ADDRESS</StyledTableCell>
                        <StyledTableCell>STATE/ZIP</StyledTableCell>
                        <StyledTableCell>APN NUMBER</StyledTableCell>
                        <StyledTableCell>SERVICE STATUS</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.customersList.addresses && props.customersList.addresses.map((each) => (
                            <StyledTableRow key={each.id} className="cursPoint">
                                <StyledTableCell onClick={() => navigate(`/Customer/${each.id}`)}>
                                    {/* {each.profilePic ? <img src={each.profilePic} alt="profile-pic" className="mr1 profile-pic-table vam" /> : <AccountCircleOutlinedIcon className="vam mr1" />} */}
                                    <span>{getFullName(each)}</span>
                                </StyledTableCell>
                                <StyledTableCell onClick={() => navigate(`/Customer/${each.id}`)}>{each.address}</StyledTableCell>
                                <StyledTableCell onClick={() => navigate(`/Customer/${each.id}`)}>{each.state} - {each.zipcode}</StyledTableCell>
                                <StyledTableCell onClick={() => navigate(`/Customer/${each.id}`)}>{each.apnNumber}</StyledTableCell>
                                <StyledTableCell>
                                    <div onClick={() => getCustomerData(each)} className={"statusBtn " + (each.requestStatus)} >{each.requestStatus}</div>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

CustomerTable.propTypes = {
    customersList: PropTypes.object,
    modalReschedulefn: PropTypes.func
};

export default CustomerTable;