import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Container, Grid } from "@mui/material";
import Header from "../Header/Header";
import Button from "../../Shared/Button";
import CustomerTable from "./CustomerTable";
import AddModal from "../../Shared/AddModal";
import { CustomerTypes } from "../../redux/action_types/customer_types";
import Search from "../../Shared/Search";
import { cloneDeep, debounce } from "lodash";
import Checkbox from "../../Shared/Checkbox";
import Pagination from "../../Shared/Pagination";
import ModalScheduleService from "../CustomerDetails/modalScheduleService";
import ModalRescheduleService from "./modalRescheduleService";
import { TechnicianTypes } from "../../redux/action_types/technician_types";
import PositionedSnackBar from "../PositionedSnackBar/PositionedSnackBar";

/**
 * Returning required redux state data
 * @param {Object} state - Redux state
 */
const getReduxData = (state) => {
  return {
    CustomerData: state.Customer,
  };
};

const Customers = () => {
  const dispatch = useDispatch();
  const data = useSelector(getReduxData);
  const [state, setState] = useState({
    openModal: false,
    limit: 10,
    page: 1,
    sortField: "firstName",
    sortOrder: "asc",
    searchText: "",
    serviceStatus: [],
    totalCount: 0,
    scheduleServiceData: {
      customerNotes: "",
      address: "",
      requestId: "",
      addressId: "",
    },
  });
  const debounceLoadData = useCallback(debounce(getCustomers, 500), []);

  // const [snackState, setSnackState] = React.useState({
  //   open: false,
  //   vertical: "top",
  //   horizontal: "right",
  //   message: "",
  //   error_state: false,
  //   auto_hide_duration: 3000,
  // });
  // const handleOpenSnackBar = (pop_message, pop_error) => {
  //   setSnackState({
  //     ...snackState,
  //     open: true,
  //     message: pop_message,
  //     error_state: pop_error,
  //   });
  // };
  // const handleCloseSnackBar = () => {
  //   setSnackState({
  //     ...snackState,
  //     open: false,
  //     message: "",
  //     error_state: false,
  //   });
  // };

  const addCustomerFn = () => {
    setState({ ...state, openModal: true });
  };

  const handleCloseModal = (val) => {
    setState({ ...state, openModal: false, openReScheduleService: false });
    if (val) {
      getCustomers(state);
    }
  };

  useEffect(() => {
    if (state.searchText) {
      debounceLoadData(state);
    } else {
      getCustomers(state);
    }
  }, [dispatch, state.serviceStatus, state.searchText, state.page]);

  function getTechnicians() {
    dispatch({
      type: TechnicianTypes.TECHNICIANS_WITH_REQUESTS_REQUEST,
    });
  }

  const getCustomerDetails = (customer) => {
    dispatch({
      type: CustomerTypes.GET_CUSTOMER_REQUEST,
      params: customer.id,
    });
  };

  function getCustomers(data) {
    const paramsObj = cloneDeep(data);
    delete paramsObj["openModal"];
    delete paramsObj["data"];
    paramsObj["searchText"] = data.searchText;
    dispatch({
      type: CustomerTypes.CUSTOMER_LIST_REQUEST,
      params: paramsObj,
    });
  }

  const setSearchData = (data) => {
    setState({ ...state, data });
  };

  const setPageNumber = (pageNo) => {
    setState({ ...state, page: pageNo });
  };

  const setCheckBoxData = (value, keyName, listName) => {
    let getSelectedList = cloneDeep(state[listName]);
    if (value) {
      getSelectedList.push(keyName);
    } else {
      getSelectedList = getSelectedList.filter((e) => e !== keyName);
    }
    setState({ ...state, [listName]: getSelectedList });
  };

  const {
    customerDetails,
    requested,
    addressData,
    completed,
    scheduled,
    invoices,
  } = data.CustomerData.customerDetails;

  const openReScheduleService = (customer) => {
    // let modalData = state.scheduleServiceData;
    // modalData.address = addressData.address;
    // modalData.addressId = addressData.id;
    // modalData.requestId =
    //   requested && requested.length > 0 ? requested[0].id : "";
    // modalData.customerNotes =
    //   requested && requested.length > 0 ? requested[0].additionalComments : "";
    getTechnicians();
    getCustomerDetails(customer);
    setState({
      ...state,
      openReScheduleService: true,
      scheduleServiceData: customer,
    });
  };

  const totalCount = data.CustomerData.customerList.total;
  const totalPages = Math.ceil(totalCount / state.limit);
  return (
    <Container className="main">
      <Header />
      <Box className="ph3">
        <h2 className="title mt3">CUSTOMERS</h2>
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={2.2}>
            <Button
              text={"ADD CUSTOMER"}
              btnClick={addCustomerFn}
              className="primary-btn full-width"
            />
            <Box className="mt2">
              <Search
                keyName={"searchText"}
                data={state}
                setSearchData={setSearchData}
              />
              <div>
                <div className="full-border-grey p1">
                  <div>SERVICE STATUS</div>
                  <div>
                    <Checkbox
                      keyName={"REQUESTED"}
                      listName={"serviceStatus"}
                      isChecked={
                        state["serviceStatus"].indexOf("REQUESTED") > -1
                      }
                      setCheckBoxData={setCheckBoxData}
                    />
                    <span className="fntSm pt0">Service Requested</span>
                  </div>
                  <div>
                    <Checkbox
                      keyName={"SCHEDULED"}
                      listName={"serviceStatus"}
                      isChecked={
                        state["serviceStatus"].indexOf("SCHEDULED") > -1
                      }
                      setCheckBoxData={setCheckBoxData}
                    />
                    <span className="fntSm pt0">Service Scheduled</span>
                  </div>
                  <div>
                    <Checkbox
                      keyName={"NO ISSUE"}
                      listName={"serviceStatus"}
                      isChecked={
                        state["serviceStatus"].indexOf("NO ISSUE") > -1
                      }
                      setCheckBoxData={setCheckBoxData}
                    />
                    <span className="fntSm pt0">No Issue</span>
                  </div>
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item xs={12} md={9.8} className="pl3">
            <CustomerTable
              customersList={data.CustomerData.customerList}
              modalReschedulefn={openReScheduleService}
            />
            {totalCount > 1 && (
              <Pagination
                count={totalPages}
                setPageNumber={setPageNumber}
                currentPage={state.page}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <AddModal
        openModal={state.openModal}
        type={"customer"}
        handleCloseModal={handleCloseModal}
        title={"Add a Customer"}
        btnText={"ADD CUSTOMER"}
        // handleOpenSnackBar={handleOpenSnackBar}
      />
      {state.openReScheduleService && (
        <ModalRescheduleService
          openModal={state.openReScheduleService}
          handleCloseModal={handleCloseModal}
          data={state.scheduleServiceData}
        />
      )}
      {/* <PositionedSnackBar
        snackState={snackState}
        handleCloseSnackBar={handleCloseSnackBar}
      /> */}
    </Container>
  );
};

export default Customers;
