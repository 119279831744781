import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import AttachFileIcon from "../images/icon-attach-file.svg";

const UploadImage = (props) => {
    const { data, keyName, setData, profileUrl } = props;
    const inputEl = useRef(null);

    const handleFileChange = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            let imageName = files[0].name;
            const sFileExtension = imageName.split('.')[imageName.split('.').length - 1].toLowerCase();
            const imageFormats = ['png', 'jpg', 'jpeg', 'gif', 'tif', 'svg'];
            if (imageFormats.indexOf(sFileExtension) === -1) {
                document.getElementById('uploadBtn').value = "";
                alert("Please upload valid image.")
                return true;
            }
            data[keyName] = files[0];
            setData(data);
        }
    }

    // function to trigger our input file click
    const uploadClick = () => {
        inputEl.current.click();
    };

    const removeProfile = () => {
        data[profileUrl] = '';
        data[keyName] = '';
        setData(data);
    };

    return (
        <Box className="mb2">
            {
                data[profileUrl] ?
                    <div className="flx">
                        <div>
                            <img src={data[profileUrl]} alt="profilepic" className="profile-image" width="80" height="80" />
                        </div>
                        <div className="pl2">
                            <a href="#" className="underline fntGreen pr1 d-block mb0-5" onClick={uploadClick}>Upload new image</a>
                            <a href="#" className="underline fntGreen pr1" onClick={removeProfile}>Remove</a>
                        </div>
                    </div>
                    :
                    <div className="flx align-center justify-center upload-file mt2" onClick={uploadClick}>
                        <img src={AttachFileIcon} alt="attach-file" className="pr1" />
                        <a href="#" className="underline fntGreen pr1">Add File</a>
                        <span>or drop profile picture here</span>
                    </div>
            }
            <input type="file" hidden id="profile-pic" onChange={handleFileChange} ref={inputEl} />
        </Box>
    )
}

UploadImage.propTypes = {
    data: PropTypes.object,
    setData: PropTypes.func,
    profileUrl: PropTypes.string,
    keyName: PropTypes.string,

};

export default UploadImage;