import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";
import { DashboardTypes } from "../action_types/dashboard_types";
import API from "../api";

// Get Service Request 
function* getServiceRequests() {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.SERVICE_REQUESTS}`;
    const res = yield API.get(url);
    yield put({ type: DashboardTypes.SERVICE_REQUESTS_SUCCESS, data: res.data.data });
    // callback(false);
  } catch (e) {
    // callback(true);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

// Get Top Technicians List
function* getTopTechniciansList({ params }) {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.TOP_TECHNICIANS}/${params.days}`;
    const res = yield API.get(url);
    yield put({ type: DashboardTypes.TOP_TECHNICIANS_SUCCESS, data: res.data.topTechnicians });
    // callback(false);
  } catch (e) {
    // callback(true);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

// Get Account break down
function* getAccountBreakDown() {
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.ACCOUNT_BREAKDOWN}`;
    const res = yield API.get(url);
    yield put({ type: DashboardTypes.ACCOUNT_BREAKDOWN_SUCCESS, data: res.data.data });
  } catch (e) {
  }
}

// Get DeviceInstalls
function* getDeviceInstalls({ params }) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.DEVICE_INSTALLS}/${params}`;
    const res = yield API.get(url);
    yield put({ type: DashboardTypes.DEVICE_INSTALLS_SUCCESS, data: res.data.data });
  } catch (e) {
  }
}
// Get Services scheduled
function* getServicesScheduled({ params }) {
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.SERVICES_SCHEDULED}/${params}`;
    const res = yield API.get(url);
    yield put({ type: DashboardTypes.SERVICES_SCHEDULED_SUCCESS, data: res.data.data.scheduledServices || [] });
  } catch (e) {
  }
}


export default function* DashboardSaga() {
  yield takeEvery(DashboardTypes.SERVICE_REQUESTS_REQUEST, getServiceRequests);
  yield takeEvery(DashboardTypes.TOP_TECHNICIANS_REQUEST, getTopTechniciansList);
  yield takeEvery(DashboardTypes.ACCOUNT_BREAKDOWN_REQUEST, getAccountBreakDown);
  yield takeEvery(DashboardTypes.DEVICE_INSTALLS_REQUEST, getDeviceInstalls);
  yield takeEvery(DashboardTypes.SERVICES_SCHEDULED_REQUEST, getServicesScheduled);
}
