const CustomerTypes = {
    ADD_CUSTOMER_REQUEST: "ADD_CUSTOMER_REQUEST",
    ADD_CUSTOMER_SUCCESS: "ADD_CUSTOMER_SUCCESS",
    EDIT_CUSTOMER_REQUEST: "EDIT_CUSTOMER_REQUEST",
    EDIT_CUSTOMER_SUCCESS: "EDIT_CUSTOMER_SUCCESS",
    CUSTOMER_LIST_REQUEST: "CUSTOMER_LIST_REQUEST",
    CUSTOMER_LIST_SUCCESS: "CUSTOMER_LIST_SUCCESS",
    GET_CUSTOMER_REQUEST: "GET_CUSTOMER_REQUEST",
    GET_CUSTOMER_SUCCESS: "GET_CUSTOMER_SUCCESS",

    UPLOAD_INVOICE_REQUEST: "UPLOAD_INVOICE_REQUEST",
    UPLOAD_INVOICE_SUCCESS: "UPLOAD_INVOICE_SUCCESS",
};

export { CustomerTypes };
