import React from "react";
import PropTypes from "prop-types";
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import PhoneInTalkOutlinedIcon from '@mui/icons-material/PhoneInTalkOutlined';
import { Search } from "@mui/icons-material";

const Input = (props) => {
  const { data, keyName, setData, showError, icon } = props;
  // On change event for Input field.
  const handleInputChange = (e, isBlur) => {
    const value = isBlur ? e.target.value.trim() : e.target.value;
    data[keyName] = value;
    setData(data);
  }

  return (
    <TextField
      type={props.type}
      className={`mb2 full-width text-box ${showError && 'borderRed'} ${props.className}`}
      placeholder={props.placeholder}
      autoComplete="off"
      InputProps={icon ? {
        startAdornment: (
          <InputAdornment position="start">
            {
              icon === 'email' ?
                <EmailOutlinedIcon />
                :
                icon === 'password' ?
                  <LockOutlinedIcon />
                  :
                  icon === 'phoneNumber' ?
                    <PhoneInTalkOutlinedIcon />
                    :
                    icon === 'name' ?
                      <AccountCircleOutlinedIcon />
                      :
                      icon === 'search' &&
                      <Search />
            }
          </InputAdornment>
        ),
      } : null}
      value={data[keyName]}
      onChange={(e) => handleInputChange(e, props.onChangeTrim ? true : false)}
      onBlur={(e) => handleInputChange(e, true)}
    />
  )
}

Input.propTypes = {
  icon: PropTypes.string,
  data: PropTypes.object,
  className: PropTypes.string,
  keyName: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  setData: PropTypes.func,
  onChangeTrim: PropTypes.bool,
  showError: PropTypes.bool,
};

export default Input;