import { TechnicianTypes } from "../action_types/technician_types";

const initialState = {
  technicianList: {
    technicians: []
  },
  techniciansWithRequests: {
    technicians: []
  },
  technicianDetails: {
    technicianDetails: {},
    requested: [],
    completed: [],
    scheduled: [],
    invoices: []
  },
  scheduleService:{}
};

export default function TechnicianReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case TechnicianTypes.TECHNICIAN_LIST_SUCCESS: {
      return {
        ...state,
        technicianList: action.data
      };
    }
    case TechnicianTypes.GET_TECHNICIAN_SUCCESS: {
      return {
        ...state,
        technicianDetails: action.data
      };
    }
    case TechnicianTypes.TECHNICIANS_WITH_REQUESTS_SUCCESS: {
      return {
        ...state,
        techniciansWithRequests: action.data
      };
    }
    case TechnicianTypes.SCHEDULE_SERVICE_SUCCESS: {
      return {
        ...state,
        scheduleService: action.data
      };
    }
    default:
      return state;
  }
}
