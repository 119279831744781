import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import "./PositionedSnackBar.css";
import check_circle_icon from "../../images/check-circle-svgrepo.png";
import cross_circle_icon from "../../images/cross-circle-svgrepo.png";
import { SnackTypes } from "../../redux/action_types/snack_types";
import { useDispatch, useSelector } from "react-redux";

export default function PositionedSnackBar() {
  const dispatch = useDispatch();

  const snackState = useSelector((state) => state.Snackbar);

  const handleCloseSnackBar = () => {
    dispatch({
      type: SnackTypes.CLOSE_SNACKBAR,
    });
  };

  return (
    <Box sx={{ width: 500 }}>
      <Snackbar
        anchorOrigin={{
          vertical: snackState.vertical,
          horizontal: snackState.horizontal,
        }}
        open={snackState.open}
        onClose={handleCloseSnackBar}
        key={snackState.vertical + snackState.horizontal}
        autoHideDuration={snackState.auto_hide_duration}
        children={
          <div className="css-1eqdgzv-MuiPaper-root-MuiSnackbarContent-root">
            <img
              src={
                !snackState.error_state ? check_circle_icon : cross_circle_icon
              }
              width="25vh"
              style={{ marginRight: "1vh" }}
            />
            <div>{snackState.message}</div>
          </div>
        }
      />
    </Box>
  );
}
