import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  Box,
  IconButton,
  Modal,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Button,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { TechnicianTypes } from "../../redux/action_types/technician_types";
import { CustomerTypes } from "../../redux/action_types/customer_types";
import { useParams } from "react-router-dom";
import { SnackTypes } from "../../redux/action_types/snack_types";

const getReduxData = (state) => {
  return {
    TechnicianData: state.Technician,
  };
};

const ModalScheduleService = (props) => {
  const dispatch = useDispatch();
  const Reduxdata = useSelector(getReduxData);
  const { technicians } = Reduxdata.TechnicianData.techniciansWithRequests;
  const { data, handleCloseModal, openModal } = props;
  const [scheduledTime, setScheduledTime] = React.useState(null);
  const [technicianId, setTechnicianId] = React.useState("");
  const [technicianError, setTechnicianError] = React.useState(false);
  const [dateError, setDateError] = React.useState(false);
  const [hourError, setHourError] = React.useState(false);
  const [minuteError, setMinuteError] = React.useState(false);
  const [ampmError, setAmpmError] = React.useState(false);
  const [ampmValue, setAmpmValue] = useState("AM");

  const urlParams = useParams();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    padding: "15px",
  };

  useEffect(() => {
    dispatch({
      type: TechnicianTypes.TECHNICIANS_WITH_REQUESTS_REQUEST,
    });
  }, [dispatch]);

  const handleCreateNewAppointment = (e) => {
    e.preventDefault();

    let isValid = true;
    if (!technicianId) {
      setTechnicianError(true);
      isValid = false;
      return isValid;
    } else {
      setTechnicianError(false);
    }
    if (!scheduledTime) {
      setDateError(true);
      isValid = false;
      return isValid;
    } else {
      setDateError(false);
    }

    if (isValid) {
      // Perform the action to create the appointment
      // Proceed with creating the appointment
      // Add your appointment creation logic here
      const formattedScheduledTime = new Date(scheduledTime).toISOString();
      const paramsObj = {
        addressId: data.addressId,
        requestId: data.requestId,
        technicianId: technicianId,
        scheduledTime: formattedScheduledTime,
      };
      dispatch({
        type: TechnicianTypes.SCHEDULE_SERVICE_REQUEST,
        params: paramsObj,
        callback: (res) => {
          if (res.error === false) {
            dispatch({
              type: CustomerTypes.GET_CUSTOMER_REQUEST,
              params: urlParams.id,
            });
            dispatch({
              type: SnackTypes.OPEN_SNACKBAR,
              message: res.message,
              error_state: false,
            });
          } else {
            dispatch({
              type: SnackTypes.OPEN_SNACKBAR,
              message: res.message,
              error_state: true,
            });
          }
        },
      });
      handleCloseModal();
    } else {
      setTechnicianError(true);
      setDateError(true);
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // Adding 1 because months are zero-based
    const day = date.getDate();
    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`;
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            className="p1 fntMd mt1"
            variant="h6"
          >
            Schedule Service For:
            <br /> {data.address}
            <IconButton
              onClick={() => handleCloseModal()}
              className="modalClose"
            >
              <CancelOutlinedIcon />
            </IconButton>
          </Typography>

          <Typography id="modal-modal-description" className="p1">
            <FormControl sx={{ m: 1, width: 360 }}>
              <Select
                id="demo-multiple-name"
                displayEmpty
                error={technicianError} // Apply error styling if there is an error
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <>
                        <AccountCircleOutlinedIcon className="mr1" />{" "}
                        <div style={{ marginLeft: "31px", marginTop: "-28px" }}>
                          Select Technician
                        </div>
                      </>
                    );
                  }
                  return (
                    <>
                      <AccountCircleOutlinedIcon className="mr1" />
                      <div style={{ marginLeft: "31px", marginTop: "-28px" }}>
                        {selected}
                      </div>
                    </>
                  );
                }}
                inputProps={{ "aria-label": "Without label" }}
              >
                {technicians.map((each) => (
                  <MenuItem
                    key={each.id}
                    onClick={() => {
                      setTechnicianId(each.id);
                      setTechnicianError(false); // Clear technician error on selection
                    }}
                    value={each.firstName + " " + each.lastName}
                  >
                    <AccountCircleOutlinedIcon className="mr1" />
                    {" " + each.firstName + " " + each.lastName}
                  </MenuItem>
                ))}
              </Select>
              {technicianError && (
                <Typography variant="caption" color="error">
                  Please select a technician.
                </Typography>
              )}
            </FormControl>

            {/* Date Picker */}
            <FormControl sx={{ m: 1, width: 360 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  renderInput={(props) => <TextField {...props} />}
                  label={
                    <>
                      <CalendarMonthOutlinedIcon />{" "}
                      <div style={{ marginLeft: "31px", marginTop: "-28px" }}>
                        Date
                      </div>
                    </>
                  }
                  value={scheduledTime ? new Date(scheduledTime) : null}
                  error={dateError}
                  onChange={(newValue) => {
                    const formattedDate = formatDate(newValue);
                    const timePart = scheduledTime
                      ? scheduledTime.split(" ")[1]
                      : "00:00 AM";
                    setScheduledTime(
                      dayjs(formattedDate + " " + timePart).format(
                        "YYYY-MM-DD HH:mm:ss"
                      )
                    );

                    setDateError(false);
                  }}
                  style={
                    dateError ? { border: " 1px solid red !important" } : {}
                  }
                />
              </LocalizationProvider>
              <div>
                {dateError && (
                  <Typography variant="caption" color="error">
                    Please select a date.
                  </Typography>
                )}
              </div>
            </FormControl>

            {/* Hour Selector */}
            <Grid
              container
              spacing={1}
              direction="row"
              marginBottom="30px"
              paddingLeft="10px"
              marginTop="-1px"
            >
              <Grid item>
                <TextField
                  select
                  label={scheduledTime ? "Hour" : "(Hr)"}
                  variant="outlined"
                  value={
                    scheduledTime
                      ? scheduledTime.split(" ")[1].split(":")[0]
                      : ""
                  }
                  onChange={(e) => {
                    const hour = e.target.value;
                    const parts = scheduledTime
                      ? scheduledTime.split(" ")
                      : ["", "00:00", "AM"];
                    setScheduledTime(
                      parts[0] + " " + hour + ":" + parts[1] + " " + ampmValue
                    ); // Use the stored AM/PM value
                  }}
                  sx={{
                    width: "107px",
                    height: "30px",
                    ...(hourError && { border: "1px solid red" }),
                  }}
                  error={hourError}
                >
                  {Array.from({ length: 12 }, (_, i) => (i + 1).toString()).map(
                    (hour) => (
                      <MenuItem key={hour} value={hour}>
                        {hour}
                      </MenuItem>
                    )
                  )}
                </TextField>
              </Grid>

              {/* Minute Selector */}
              <Grid item>
                <TextField
                  select
                  label={scheduledTime ? "Minute" : "(Min)"}
                  variant="outlined"
                  value={
                    scheduledTime
                      ? scheduledTime.split(" ")[1].split(":")[1]
                      : "00"
                  }
                  // {/* Set the default value to "00" */}
                  onChange={(e) => {
                    const minute = e.target.value;
                    const parts = scheduledTime
                      ? scheduledTime.split(" ")
                      : ["", "00:00", "AM"];
                    setScheduledTime(
                      parts[0] +
                        " " +
                        parts[1].split(":")[0] +
                        ":" +
                        minute +
                        " " +
                        parts[2]
                    );
                  }}
                  sx={{
                    width: "110px",
                    height: "30px",
                    ...(minuteError && { border: "1px solid red" }),
                  }}
                >
                  {[0, 15, 30, 45].map((minute) => (
                    <MenuItem
                      key={dayjs().minute(minute).format("mm")}
                      value={dayjs().minute(minute).format("mm")}
                    >
                      {dayjs().minute(minute).format("mm")}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              {/* AM/PM Selector */}
              <Grid item>
                <TextField
                  select
                  label={scheduledTime ? "AM/PM" : "(AM/PM)"}
                  variant="outlined"
                  value={ampmValue}
                  // {/* Use the stored AM/PM value here */}
                  onChange={(e) => {
                    const ampm = e.target.value;
                    setAmpmValue(ampm); // Update the stored AM/PM value
                    const parts = scheduledTime
                      ? scheduledTime.split(" ")
                      : ["", "00:00", "AM"];
                    setScheduledTime(parts[0] + " " + parts[1] + " " + ampm);
                  }}
                  sx={{
                    width: "120px",
                    height: "30px",
                    ...(ampmError && { border: "1px solid red" }),
                  }}
                >
                  {["AM", "PM"].map((format) => (
                    <MenuItem key={format} value={format}>
                      {format}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Typography>
          <Typography className="p1 bt2">
            <FormControl sx={{ m: 1, width: 360 }}>
              <h5 className="mb1 mt0">CUSTOMER NOTES</h5>
              <p className="mb1-5 mt0 customerNotes">{data.customerNotes}</p>
            </FormControl>
          </Typography>
          <Typography className="tac">
            <FormControl sx={{ m: 1, width: 360 }}>
              <Button
                variant="outlined"
                onClick={(e) => handleCreateNewAppointment(e)}
              >
                CREATE APPOINTMENT
              </Button>
            </FormControl>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default ModalScheduleService;
