import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DashboardTypes } from "../../redux/action_types/dashboard_types";
import { Box, Container, Grid, Paper, styled, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { tableCellClasses } from '@mui/material/TableCell';
import Header from "../Header/Header";
import BarChart from "../../Shared/BarChart";
import Dropdown from "../../Shared/Dropdown";
import LineChart from "../../Shared/LineChart";
import Rating from "../../Shared/Rating";
import { getListOfNames } from "../../Shared/Utils";

/**
 * Returning required redux state data
 * @param {Object} state - Redux state
 */
const getReduxData = (state) => {
    return {
        DashboardData: state.Dashboard
    };
};

const Dashboard = () => {
    const dispatch = useDispatch();
    const [state, setState] = useState({
        technicianSelectedDay: 30,
        serviceSchedulesSelectedDay: 30,
        deviceInstallsSelectedDay: 30,
        accountBreakDownData: {
            labels: ["Paid", "Free"],
            datasets: [
                {
                    data: ['30', '70'],
                    backgroundColor: ["#5EA472", "#1A7E36"],
                    borderWidth: 0
                }
            ],
        },
        serviceRequestsData: {
            labels: ["General Service", "Service Alert", "Device Disconnect"],
            datasets: [
                {
                    data: ['10', '20', '70'],
                    backgroundColor: ["#BAD8C2", "#5EA472", "#1A7E36"],
                    borderWidth: 0
                }
            ],
        }
    })

    const data = useSelector(getReduxData);

    const setData = (data) => {
        setState({ ...state, data })
    }

    useEffect(() => {
        dispatch({
            type: DashboardTypes.SERVICE_REQUESTS_REQUEST,
        })
        dispatch({
            type: DashboardTypes.ACCOUNT_BREAKDOWN_REQUEST,
        })
       
    }, [dispatch]);

    useEffect(() => {
        dispatch({
            type: DashboardTypes.TOP_TECHNICIANS_REQUEST,
            params: {
                days: state.technicianSelectedDay,
            }
        })
    }, [dispatch, state.technicianSelectedDay]);

    useEffect(() => {
        dispatch({
            type: DashboardTypes.SERVICES_SCHEDULED_REQUEST,
            params: state.serviceSchedulesSelectedDay
        })
    }, [dispatch, state.serviceSchedulesSelectedDay]);

    useEffect(() => {
        dispatch({
            type: DashboardTypes.DEVICE_INSTALLS_REQUEST,
            params: state.deviceInstallsSelectedDay
        })
    }, [dispatch, state.deviceInstallsSelectedDay]);

    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F4F5F5',
            color: '#43505C',
            opacity: 0.7,
            border: 0,
            fontSize: 13,
            padding: 10
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            fontWeight: 'bold',
            color: '#43505C'
        },
    }));

    const StyledTableRow = styled(TableRow)(() => ({
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));


    const getAccountBreakDownData = () => {
        const accountBreakDown = data.DashboardData.accountBreakdown;
        if (accountBreakDown && accountBreakDown.length > 0) {
            return {
                labels: getListOfNames(accountBreakDown, "name"),
                datasets: [
                    {
                        data: getListOfNames(accountBreakDown, "count"),
                        backgroundColor: ["#5EA472", "#1A7E36"],
                        borderWidth: 0
                    }
                ],
            }
        }
        return null;
    }

    const getServiceRequestData = () => {
        const serviceRequests = data.DashboardData.serviceRequests;
        if (serviceRequests) {
            return {
                labels: ["General Service", "Service Alert", "Device Disconnect"],
                datasets: [
                    {
                        data: [serviceRequests.general || 0, serviceRequests.technical || 0, 0],
                        backgroundColor: ["#BAD8C2", "#5EA472", "#1A7E36"],
                        borderWidth: 0
                    }
                ],
            }
        }
        return null;
    }

    return (
        <Container className="main">
            <Header />
            <Box className="ph3 mt3">
                <Grid item xs={12}>
                    <Grid container spacing={4}>
                        <Grid item xs={4}>
                            <Paper className="full-border-grey box-shadow-inherit pv2 ph2">
                                <h2 className="fntMd m0">Account Breakdown</h2>
                                {getAccountBreakDownData() && <BarChart data={getAccountBreakDownData()} />}
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className="full-border-grey box-shadow-inherit pv2 ph2">
                                <h2 className="fntMd m0">Service Requests</h2>
                                {getServiceRequestData() && <BarChart data={getServiceRequestData()} />}
                            </Paper>
                        </Grid>
                        <Grid item xs={4}>
                            <Paper className="full-border-grey box-shadow-inherit pv2 ph2">
                                <div className="flx justify-between align-center">
                                    <h2 className="fntMd m0">Top Technicians</h2>
                                    <Dropdown data={state} setData={setData} keyName={'technicianSelectedDay'} />
                                </div>
                                <Table aria-label="customized table">
                                    <TableBody>
                                        {
                                            data.DashboardData.topTechniciansList && data.DashboardData.topTechniciansList.length > 0 &&
                                            data.DashboardData.topTechniciansList.map((each, index) => {
                                                return (

                                                    <StyledTableRow key={index}>
                                                        <StyledTableCell component="th" scope="row">
                                                            {each.firstName} {each.lastName}
                                                        </StyledTableCell>
                                                        <StyledTableCell style={{ whiteSpace: "nowrap" }}>
                                                            <Rating rate={each.rate} />
                                                        </StyledTableCell>
                                                    </StyledTableRow>
                                                )
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Paper className="full-border-grey box-shadow-inherit mt2 pv2 ph2">
                        <div className="flx justify-between align-center">
                            <h2 className="fntMd m0">Services scheduled</h2>
                            <Dropdown data={state} setData={setData} keyName={'serviceSchedulesSelectedDay'} />
                        </div>
                        {data.DashboardData.servicesScheduled && data.DashboardData.servicesScheduled.length > 0 && <LineChart data={data.DashboardData.servicesScheduled} labelName={"scheduleTime"} countKeyName={"requestCount"} displayName={"Service scheduled"} />}
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper className="full-border-grey box-shadow-inherit mv2 pv2 ph2">
                        <div className="flx justify-between align-center">
                            <h2 className="fntMd m0">Device Installs</h2>
                            <Dropdown data={state} setData={setData} keyName={'deviceInstallsSelectedDay'} />
                        </div>
                        {data.DashboardData.deviceInstalls && data.DashboardData.deviceInstalls.length > 0 && <LineChart data={data.DashboardData.deviceInstalls} labelName={"name"} countKeyName={"count"} displayName={"Device Install"} />}
                    </Paper>
                </Grid>

            </Box>
        </Container>
    )
}

export default Dashboard;