import React from "react";
import PropTypes from "prop-types";
import { Doughnut } from 'react-chartjs-2';
import Chart from 'chart.js/auto';

const BarChart = (props) => {

    const options = {
        responsive: true,
        maintainAspectRatio: true
    };


    return (
        <div className="mt1">
            <Doughnut
                data={props.data}
                options={options}
            />
        </div>
    )
}

BarChart.propTypes = {
    data: PropTypes.object,
};

export default BarChart;