import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box, Link } from "@mui/material";
import Logo from "../../../images/pumperpal-logo-light.svg";
import Input from "../../../Shared/Input";
import Button from "../../../Shared/Button";
import { isEmailValid } from "../../../Shared/Utils";
import { AuthTypes } from "../../../redux/action_types/auth_types";

const Login = () => {
    const [state, setState] = useState({
        loginForm: {
            email: '',
            password: ''
        },
        showError: false
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setData = (data) => {
        setState({ ...state, loginForm: data, showError: false });
    }

    const submitFn = (e) => {
        e.preventDefault()
        const { loginForm } = state;
        if (isEmailValid(loginForm.email)) {
            dispatch({
                type: AuthTypes.LOGIN_REQUEST,
                payload: loginForm,
                callback: (error) => {
                    if (error) {
                        setState({ ...state, showError: true })
                        return;
                    }
                    navigate("/Dashboard");
                },
            });
        } else {
            setState({ ...state, showError: true })
        }
    }

    const { loginForm, showError } = state;
    return (
        <Box className="global-auth-container">
            <div className="auth-side-container">
                <div className="tac pv3">
                    <img src={Logo} alt="logo" />
                </div>
                <form id="authform">
                    <div className="mt5 tac ph5">
                        <h2 className="fntWhite mb5 letter-spacing-lg fntXLg">LOGIN</h2>
                        {
                            showError &&
                            <div className="mv1-5">
                                <h4 className="tac fntSm fntInputTitle m0">Incorrect email address/password.</h4>
                                <h4 className="tac fntSm fntInputTitle m0">Please try again.</h4>
                            </div>
                        }
                        <form onSubmit={submitFn}>
                            <Input type={"text"} icon={"email"} keyName={'email'} data={loginForm} setData={setData} placeholder={"Email Address"} showError={showError} />
                            <Input type={"password"} icon={"password"} keyName={'password'} data={loginForm} setData={setData} placeholder={"Password"} onChangeTrim={true} showError={showError} />
                            <Button text={"LOGIN"} type={"submit"} btnClick={submitFn} disabled={!loginForm.email || !loginForm.password} className="full-width primary-btn letter-spacing-lg mt2" />
                        </form>
                    </div>
                </form>
                <div className="mt2 tac">
                    <Link href="/forgotPassword" className="underline fntWhite fntMd">Forgot your password?</Link>
                </div>
                {/* <div className="mt2 tac link-footer full-width">
                    <a href="/signup" className="underline fntWhite fntMd">Create a free account</a>
                </div> */}

            </div>
        </Box>
    )
}

export default Login;