import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { styled, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import StartIcon from "../../images/icon-star.svg";
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { getFullName } from "../../Shared/Utils";


const TechnicianTable = (props) => {
    const { sortTechnician } = props;
    const navigate = useNavigate();
    const StyledTableCell = styled(TableCell)(() => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: '#F4F5F5',
            color: '#43505C',
            opacity: 0.7,
            border: 0,
            fontSize: 13,
            padding: 10
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            fontWeight: 'bold',
            color: '#43505C'
        },
    }));

    const StyledTableRow = styled(TableRow)(() => ({
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));


    return (
        <TableContainer component={Paper} style={{ boxShadow: 'none', borderRadius: '0px' }}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                    <TableRow>
                        <StyledTableCell className="posRel">TECHNICIAN<ArrowDropUpIcon className="sort-icon-up" onClick={()=>sortTechnician("firstName", "asc")}/><ArrowDropDownIcon className="sort-icon-down" onClick={()=>sortTechnician("firstName", "desc")}/></StyledTableCell>
                        <StyledTableCell>CURRENT STATUS</StyledTableCell>
                        <StyledTableCell className="posRel">ASSIGNED SERVICES <ArrowDropUpIcon className="sort-icon-up" onClick={()=>sortTechnician("assignedRequests", "asc")}/><ArrowDropDownIcon className="sort-icon-down" onClick={()=>sortTechnician("assignedRequests", "desc")} /></StyledTableCell>
                        <StyledTableCell className="posRel">RATING <ArrowDropUpIcon className="sort-icon-up" onClick={()=>sortTechnician("avgRating", "asc")}/><ArrowDropDownIcon className="sort-icon-down"onClick={()=>sortTechnician("avgRating", "desc")} /></StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        props.techniciansList.technicians && props.techniciansList.technicians.map((each) => (
                            <StyledTableRow key={each.id} className="cursPoint" onClick={() => navigate(`/Technician/${each.id}`)}>
                                <StyledTableCell>
                                    {each.profilePic ? <img src={each.profilePic} alt="profile-pic" className="mr1 vam profile-image" width="30" height="30" /> : <AccountCircleOutlinedIcon className="mr1 vam"/>}{getFullName(each)}
                                </StyledTableCell>
                                <StyledTableCell>{each.isActive === 1 ? "Active" : "Inactive"}</StyledTableCell>
                                <StyledTableCell>{each.assignedRequests}</StyledTableCell>
                                <StyledTableCell><img src={StartIcon} alt="start" className="mr0-5 vam" />{each.avgRating} Average</StyledTableCell>
                            </StyledTableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}

TechnicianTable.propTypes = {
    techniciansList: PropTypes.object,
    sortTechnician: PropTypes.func
};

export default TechnicianTable;