import React from "react";
import PropTypes from "prop-types";
import { Button as Btn } from "@mui/material";

const Button = (props) => {
    return (
        <Btn type={props.type ? props.type : "text" } variant={props.variant ? props.variant : 'contained'} className={`${props.className}`} color={ props.color} disabled={props.disabled} onClick={props.btnClick}>{ props.icon}{props.text}</Btn>
    )
}

Button.propTypes = {
    btnClick: PropTypes.func,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    type: PropTypes.string,
};

export default Button;