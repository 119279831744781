const TechnicianTypes = {
  ADD_TECHNICIAN_REQUEST: "ADD_TECHNICIAN_REQUEST",
  ADD_TECHNICIAN_SUCCESS: "ADD_TECHNICIAN_SUCCESS",
  EDIT_TECHNICIAN_REQUEST: "EDIT_TECHNICIAN_REQUEST",
  EDIT_TECHNICIAN_SUCCESS: "EDIT_TECHNICIAN_SUCCESS",
  TECHNICIAN_LIST_REQUEST: "TECHNICIAN_LIST_REQUEST",
  TECHNICIAN_LIST_SUCCESS: "TECHNICIAN_LIST_SUCCESS",
  TECHNICIANS_WITH_REQUESTS_REQUEST: "TECHNICIAN_LIST_REQUEST",
  TECHNICIANS_WITH_REQUESTS_SUCCESS: "TECHNICIANS_WITH_REQUESTS_SUCCESS",
  GET_TECHNICIAN_REQUEST: "GET_TECHNICIAN_REQUEST",
  GET_TECHNICIAN_SUCCESS: "GET_TECHNICIAN_SUCCESS",
  SCHEDULE_SERVICE_REQUEST: "SCHEDULE_SERVICE_REQUEST",
  SCHEDULE_SERVICE_SUCCESS: "SCHEDULE_SERVICE_SUCCESS",
  DELETE_TECHNICIAN_DOCUMENT: "DELETE_TECHNICIAN_DOCUMENT",

  CANCEL_SERVICE_REQUEST: "CANCEL_SERVICE_REQUEST",
  CANCEL_SERVICE_SUCCESS: "CANCEL_SERVICE_SUCCESS",
};

export { TechnicianTypes };
