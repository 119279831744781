import React from "react";
import PropTypes from "prop-types";
import { Line } from 'react-chartjs-2';
import { getListOfNames } from "./Utils";

const LineChart = (props) => {

    const options = {
        responsive: true,
        maintainAspectRatio: true
    };

    const data = {
        labels: getListOfNames(props.data, props.labelName),
        datasets: [
            {
                label: props.displayName,
                data: getListOfNames(props.data, props.countKeyName),
                fill: true,
                backgroundColor: "#FFFFFF",
                borderColor: "#B0D2B9"
            }
        ]
    };


    return (
        <div className="mt1">
            <Line
                data={data}
                options={options}
            />
        </div>
    )
}

LineChart.propTypes = {
    data: PropTypes.object,
    labelName: PropTypes.string,
    countKeyName: PropTypes.string,
    displayName: PropTypes.string,
};

export default LineChart;