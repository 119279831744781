import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import Logo from "../../../images/pumperpal-logo-light.svg";
import Input from "../../../Shared/Input";
import Button from "../../../Shared/Button";
import { isEmailValid } from "../../../Shared/Utils";
import { AuthTypes } from "../../../redux/action_types/auth_types";

const ForgotPassword = () => {
    const [state, setState] = useState({
        forgotPasswordForm: {
            email: '',
        },
        forgotPasswordSuccess: false,
        showError: false
    });
    const dispatch = useDispatch();

    const setData = (data) => {
        setState({ ...state, forgotPasswordForm: data, showError: false });
    }

    const submitFn = (e) => {
        e.preventDefault();
        const { forgotPasswordForm } = state;
        if (isEmailValid(forgotPasswordForm.email)) {
            dispatch({
                type: AuthTypes.FORGOT_PASSWORD_REQUEST,
                payload: forgotPasswordForm,
                callback: (error) => {
                    setState({ ...state, forgotPasswordSuccess: !error, showError: error });
                },
            });
        } else {
            setState({ ...state, showError: true })
        }

    }

    const { forgotPasswordForm, showError, forgotPasswordSuccess } = state;
    return (
        <Box className="global-auth-container">
            <div className="auth-side-container">
                <div className="tac pv3">
                    <img src={Logo} alt="logo" />
                </div>

                <div className="mt5 tac ph5">
                    <h2 className="fntWhite mb5 letter-spacing-lg fntXLg">FORGOT PASSWORD</h2>
                    {
                        showError &&
                        <div className="mv1-5">
                            <h4 className="tac fntSm fntInputTitle m0">Incorrect email address.</h4>
                            <h4 className="tac fntSm fntInputTitle m0">Please try again.</h4>
                        </div>
                    }
                    {
                        forgotPasswordSuccess ?
                            <h4 className="tac fntSm fntInputTitle m0">We've emailed you a link to reset your password.</h4>
                            :
                            <div>
                                <div className="mv1-5">
                                    <h4 className="tac fntSm fntInputTitle m0">Enter your email below.</h4>
                                    <h4 className="tac fntSm fntInputTitle m0">We'll send you an email to reset your password.</h4>
                                </div>
                                <div className="mt2 form">
                                    <Input type={"text"} icon={"email"} keyName={'email'} data={forgotPasswordForm} setData={setData} placeholder={"Email Address"} showError={showError} />
                                    <Button text={"RESET PASSWORD"} btnClick={submitFn} disabled={!forgotPasswordForm.email} className="full-width primary-btn letter-spacing-lg mt2" />
                                </div>
                            </div>

                    }

                </div>
                <div className="tac link-footer full-width">
                    <a href="/login" className="underline fntWhite fntMd">Back to Login</a>
                </div>

            </div>
        </Box>
    )
}

export default ForgotPassword;