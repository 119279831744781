import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";
import { CustomerTypes } from "../action_types/customer_types";
import API from "../api";

// Get list of customers
function* getCustomerList({ params }) {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.CUSTOMERS_LIST}`;
    const res = yield API.post(url, params);
    yield put({
      type: CustomerTypes.CUSTOMER_LIST_SUCCESS,
      data: res.data.data,
    });
  } catch (e) {
    console.log(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

// Add Customer
function* addCustomer({ params, userId, callback }) {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.ADD_CUSTOMER}`;
    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const res = yield API.post(url, params, headers);
    callback(res.data);
  } catch (e) {
    callback(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

// Edit Customer
function* editCustomer({ params, userId, callback }) {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.EDIT_CUSTOMER}/${userId}`;
    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const res = yield API.post(url, params, headers);
    callback(res.data);
  } catch (e) {
    callback(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

// GET Customer details
function* getCustomerDetails({ params }) {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.GET_CUSTOMER}/${params}`;
    const res = yield API.get(url);
    yield put({
      type: CustomerTypes.GET_CUSTOMER_SUCCESS,
      data: res.data.data,
    });
  } catch (e) {
    console.log(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

// GET Customer details
function* uploadInvoices({ params, callback }) {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.UPLOAD_INVOICE}`;
    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const res = yield API.post(url, params, headers);
    callback(res.data);
  } catch (e) {
    callback(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

export default function* CustomerSaga() {
  yield takeEvery(CustomerTypes.CUSTOMER_LIST_REQUEST, getCustomerList);
  yield takeEvery(CustomerTypes.ADD_CUSTOMER_REQUEST, addCustomer);
  yield takeEvery(CustomerTypes.EDIT_CUSTOMER_REQUEST, editCustomer);
  yield takeEvery(CustomerTypes.GET_CUSTOMER_REQUEST, getCustomerDetails);
  yield takeEvery(CustomerTypes.UPLOAD_INVOICE_REQUEST, uploadInvoices);
}
