import React, { useEffect } from "react";
import moment from "moment";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {
  Box,
  IconButton,
  Modal,
  Typography,
  Grid,
  Select,
  MenuItem,
  FormControl,
  Button,
  TextField,
  InputLabel,
} from "@mui/material";
import { find, get } from "lodash";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker, DateTimePicker } from "@mui/x-date-pickers";
import { useDispatch, useSelector } from "react-redux";
import BP_Blank from "../../images/BuildingPic.jpeg";
import { TechnicianTypes } from "../../redux/action_types/technician_types";
import { CustomerTypes } from "../../redux/action_types/customer_types";
import { SnackTypes } from "../../redux/action_types/snack_types";
const dayjs = require("dayjs");

const getReduxData = (state) => {
  return {
    CustomerData: state.Customer,
    TechnicianData: state.Technician,
  };
};

const ModalRescheduleService = (props) => {
  const { data, handleCloseModal, openModal } = props;
  const Reduxdata = useSelector(getReduxData);
  const dispatch = useDispatch();
  const { technicians } = Reduxdata.TechnicianData.techniciansWithRequests;
  const { customerDetails, addressData, scheduled } =
    Reduxdata.CustomerData.customerDetails;
  const [scheduledTime, setScheduledTime] = React.useState(
    moment().toISOString()
  );

  const [technician, setTechnician] = React.useState("");
  const [request, setRequest] = React.useState("");

  // Parse the initial values from scheduledTime
  const initialDate = moment(scheduledTime).format("YYYY-MM-DD");
  const scheduledTimeMoment = moment(scheduledTime, "hh:mm A");

  const initialHour = scheduledTimeMoment.format("hh");

  // Ensure that single-digit hours have a leading "0"
  const initialHourWithLeadingZero =
    initialHour < 10 ? `0${initialHour}` : initialHour;
  const initialMinute = moment(scheduledTime).format("mm");
  const initialFormat = moment(scheduledTime).format("A");

  const [selectedDate, setSelectedDate] = React.useState(initialDate);
  const [selectedHour, setSelectedHour] = React.useState(
    initialHourWithLeadingZero
  );

  const [selectedMinute, setSelectedMinute] = React.useState(initialMinute);
  const [selectedFormat, setSelectedFormat] = React.useState(initialFormat);

  useEffect(() => {
    const formattedDate = moment(scheduledTime).format("YYYY-MM-DD");
    const formattedHour = moment(scheduledTime).format("hh");
    const formattedMinute = moment(scheduledTime).format("mm");
    const formattedFormat = moment(scheduledTime).format("A");

    setSelectedDate(formattedDate);
    setSelectedHour(formattedHour);
    setSelectedMinute(formattedMinute);
    setSelectedFormat(formattedFormat);
  }, [scheduledTime]);

  const reassign = () => {
    // Combine selected date, hour, minute, and format into a single datetime string
    const newScheduledTime = `${selectedDate} ${selectedHour}:${selectedMinute} ${selectedFormat}`;
    setScheduledTime(newScheduledTime);

    // Your reassign logic here
    const req = find(scheduled, [
      "addressId",

      data.id ? data.id : data.addressId,
      ,
    ]);
    const formattedScheduledTime = new Date(newScheduledTime).toISOString();

    const paramsObj = {
      addressId: data.id ? data.id : data.addressId,
      requestId: req.id,
      technicianId: technician.id,
      scheduledTime: formattedScheduledTime,
    };

    dispatch({
      type: TechnicianTypes.SCHEDULE_SERVICE_REQUEST,
      params: paramsObj,
      callback: (res) => {
        if (res.error === false) {
          dispatch({
            type: CustomerTypes.GET_CUSTOMER_REQUEST,
            params: data.id ? data.id : data.addressId,
          });
          dispatch({
            type: TechnicianTypes.TECHNICIANS_WITH_REQUESTS_REQUEST,
          });
          dispatch({
            type: SnackTypes.OPEN_SNACKBAR,
            message: res.message,
            error_state: false,
          });
        } else {
          dispatch({
            type: SnackTypes.OPEN_SNACKBAR,
            message: res.message,
            error_state: true,
          });
        }
      },
    });

    handleCloseModal();
  };

  useEffect(() => {
    const tech = find(technicians, (tech) => {
      const req = find(tech.request, [
        "addressId",
        data.id ? data.id : data.addressId,
      ]);
      if (req) {
        setRequest(req);
        setScheduledTime(req.scheduledTime);
      }
      return req;
    });
    if (tech) {
      setTechnician(tech);
    }
  }, [technicians]);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: "800px",
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            className="p1 fntsm mt1 capitalize"
            variant="h6"
          >
            Scheduled Service Information
            <IconButton onClick={() => handleCloseModal()} className="right">
              <CancelOutlinedIcon />
            </IconButton>
          </Typography>
          <Grid container spacing={1.5} className="ph2">
            <Grid item xs={0} md={2} lg={5}>
              <img
                alt="Address Icon"
                className="p1"
                width="300px"
                src={BP_Blank}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={7}>
              <Typography
                id="modal-modal-description"
                className="ph2 border-bottom-grey pb2"
                sx={{ mt: 2 }}
              >
                <h5>LOCATION INFORMATION</h5>
                <div
                  style={{ fontSize: "14px" }}
                  className="flx justify-between"
                >
                  <label> Service Type</label>
                  <span> Service Alert</span>
                </div>
                <div
                  style={{ fontSize: "14px" }}
                  className="flx justify-between"
                >
                  <label> Service Date</label>
                  <span>
                    {" "}
                    {moment(get(request, "scheduledTime")).format(
                      "MMM Do, YYYY"
                    )}
                  </span>
                </div>
                <div
                  style={{ fontSize: "14px" }}
                  className="flx justify-between"
                >
                  <label> Service Time</label>
                  <span>
                    {" "}
                    {moment(get(request, "scheduledTime")).format("hh:mm A")}
                  </span>
                </div>
                <div
                  style={{ fontSize: "14px" }}
                  className="flx justify-between"
                >
                  <label> Address</label>
                  <span> {get(addressData, "address")}</span>
                </div>
                <div
                  style={{ fontSize: "14px" }}
                  className="flx justify-between"
                >
                  <label> State / Zip</label>
                  <span>
                    {" "}
                    {get(addressData, "state")} / {get(addressData, "zipcode")}
                  </span>
                </div>
                <div
                  style={{ fontSize: "14px" }}
                  className="flx justify-between"
                >
                  <label> Customer Name</label>
                  <span>
                    {" "}
                    {get(customerDetails, "firstName")}{" "}
                    {get(customerDetails, "lastName")}
                  </span>
                </div>
                <div
                  style={{ fontSize: "14px" }}
                  className="flx justify-between"
                >
                  <label> Customer Phone#</label>
                  <span> {get(customerDetails, "phoneNumber")}</span>
                </div>
                <div
                  style={{ fontSize: "14px" }}
                  className="flx justify-between"
                >
                  <label> Device Number</label>
                  <span> {get(addressData, "deviceNumber")}</span>
                </div>
              </Typography>
              <Typography
                id="modal-modal-description"
                className="ph2 border-bottom-grey"
              >
                <h5>CUSTOMER NOTES</h5>
                <p style={{ fontSize: "12px" }}>
                  {get(request, "additionalComments")}
                </p>
              </Typography>
              <Typography id="modal-modal-description" className="ph2 ">
                <>
                  <h5>REASSIGN SERVICE</h5>
                  <FormControl sx={{ m: 1, width: 360 }}>
                    <Select
                      id="demo-multiple-name"
                      displayEmpty
                      key={get(technician, "id")}
                      // value={get(technician)}
                      value={technician}
                      renderValue={(selected) => {
                        if (!selected) {
                          return (
                            <>
                              <AccountCircleOutlinedIcon className="mr1" />{" "}
                              <div
                                style={{
                                  marginLeft: "31px",
                                  marginTop: "-28px",
                                }}
                              >
                                Select Technician
                              </div>
                            </>
                          );
                        }
                        return (
                          <>
                            <AccountCircleOutlinedIcon className="mr1" />
                            <div
                              style={{
                                marginLeft: "31px",
                                marginTop: "-28px",
                              }}
                            >
                              {selected.firstName + " " + selected.lastName}
                            </div>
                          </>
                        );
                      }}
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      {/* {props.technicians.map((each) => { */}
                      {technicians.map((each) => {
                        return (
                          <MenuItem
                            key={each.id}
                            onClick={() => {
                              setTechnician(each);
                            }}
                            // value={each.firstName + " " + each.lastName}
                            value={each}
                          >
                            <AccountCircleOutlinedIcon className="mr1" />
                            {" " + each.firstName + " " + each.lastName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ m: 1, width: 360 }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        renderInput={(props) => <TextField {...props} />}
                        label={
                          <>
                            <CalendarMonthOutlinedIcon />{" "}
                            <div
                              style={{
                                marginLeft: "31px",
                                marginTop: "-28px",
                              }}
                            >
                              Date
                            </div>
                          </>
                        }
                        value={scheduledTime}
                        onChange={(newValue) => {
                          setScheduledTime(newValue);
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>

                  <Box>
                    <div style={{ marginBottom: "10px" }}>
                   

                      {/* </FormControl> */}
                    </div>

                    <FormControl
                      sx={{
                        width: "107px",
                        height: "30px",
                        margin: "0px 10px",
                      }} // Increase width and height here
                    >
                      <InputLabel>Hour</InputLabel>
                      <Select
                        value={selectedHour}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          // console.log(moment(newValue).format("hh"))
                          // console.log(moment(9).format("hh"))

                          setSelectedHour(newValue); // Set the selected value as-is, as a number
                        }}
                        label={
                          <>
                            {" "}
                            <div
                              style={{ marginLeft: "5px", marginTop: "-28px" }}
                            >
                              Hour
                            </div>
                          </>
                        }
                      >
                        {Array.from({ length: 12 }, (_, i) => i + 1).map(
                          (hour) => (
                            <MenuItem
                              key={dayjs().hour(hour).format("HH")}
                              value={dayjs().hour(hour).format("HH")}
                            >
                              {dayjs().hour(hour).format("HH")}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </FormControl>

                    <FormControl
                      sx={{
                        width: "107px",
                        height: "30px",
                        margin: "0px 10px",
                      }} // Increase width and height here
                    >
                      <InputLabel>Minute</InputLabel>
                      <Select
                        value={selectedMinute}
                        onChange={(e) => setSelectedMinute(e.target.value)}
                        label={
                          <>
                            {" "}
                            <div
                              style={{ marginLeft: "5px", marginTop: "-28px" }}
                            >
                              Minute
                            </div>
                          </>
                        }
                      >
                        {[0, 15, 30, 45].map((minute) => (
                          <MenuItem
                            key={dayjs().minute(minute).format("mm")}
                            value={dayjs().minute(minute).format("mm")}
                          >
                            {dayjs().minute(minute).format("mm")}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <FormControl
                      sx={{
                        width: "107px",
                        height: "30px",
                        margin: "0px 10px",
                      }} // Increase width and height here
                    >
                      <InputLabel>Format</InputLabel>
                      <Select
                        value={selectedFormat}
                        onChange={(e) => setSelectedFormat(e.target.value)}
                        label={
                          <>
                            {" "}
                            <div
                              style={{ marginLeft: "5px", marginTop: "-28px" }}
                            >
                              Format
                            </div>
                          </>
                        }
                      >
                        {["AM", "PM"].map((format) => (
                          <MenuItem key={format} value={format}>
                            {format}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Button
                    variant="outlined"
                    className="mb2 ml1"
                    onClick={reassign}
                    style={{ marginTop: "50px" }}
                  >
                    {" "}
                    Save Changes
                  </Button>
                </>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ModalRescheduleService;
