import { DashboardTypes } from "../action_types/dashboard_types";

const initialState = {
  serviceRequests: {},
  topTechniciansList: [],
  accountBreakdown: [],
  servicesScheduled: [],
  deviceInstalls: [],
};

export default function DashboardReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case DashboardTypes.SERVICE_REQUESTS_SUCCESS: {
      return {
        ...state,
        serviceRequests: action.data
      };
    }
    case DashboardTypes.TOP_TECHNICIANS_SUCCESS: {
      return {
        ...state,
        topTechniciansList: action.data
      };
    }
    case DashboardTypes.ACCOUNT_BREAKDOWN_SUCCESS: {
      return {
        ...state,
        accountBreakdown: action.data
      };
    }
    case DashboardTypes.SERVICES_SCHEDULED_SUCCESS: {
      return {
        ...state,
        servicesScheduled: action.data
      };
    }
    case DashboardTypes.DEVICE_INSTALLS_SUCCESS: {
      return {
        ...state,
        deviceInstalls: action.data
      };
    }
    default:
      return state;
  }
}
