import * as Constants from "./Constants";

export function isEmailValid(email) {
  let emailRegex = Constants.EMAIL_REGEX;
  if (!email || !emailRegex.test(email)) {
    return false;
  }
  return true;
}

export function getUserInitials() {
  const user = JSON.parse(localStorage.getItem("user"));
  return user ? `${user.firstName.charAt(0)}${user.lastName.charAt(0)}` : "";
}

export function convertDate(date) {
  return date ? new Date(date).toLocaleDateString() : "";
}

export function getFullName(user) {
  return user && user.firstName ? `${user.firstName} ${user.lastName}` : "";
}

export function getListOfNames(list, key) {
  const temp = [];
  if (list && list.length > 0) {
    for (let index of list) {
      temp.push(index[key]);
    }
    return temp;
  }
  return temp;
}

// export const numberRegex = (phoneNumber) => {
//   return /^\d*$/.test(phoneNumber);
// };

export const numberRegex = (phoneNumber) => {
  const regex = new RegExp(`^[0-9]{0,11}$`);
  return regex.test(phoneNumber);
};
