import React from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress } from "@mui/material";

/**
 * Mount the <CircularProgress> tag of material UI if loading is true else nothing
 * @param {Props} props - Component props which has loading true/false
 */
const Loader = ({ loading }) => {
    return loading && <Box className="loader">
        <CircularProgress />
    </Box>
};


Loader.propTypes = {
    loading: PropTypes.bool,
};

Loader.defaultProps = {
    loading: false,
};

export default Loader;
