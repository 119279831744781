import React from "react";
import { Container } from "@mui/material";
import Header from "../Header/Header";

const Profile = () => {
    return (
        <Container className="main">
            <Header />
            This is a Profile page.
        </Container>
    )
}

export default Profile;