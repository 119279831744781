import React from "react";
import moment from "moment";
import { IconButton, Modal, Typography } from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const PdfViewer = ({ pdf, onClose }) => {
  const url = `${window.location.origin}/pdf/web/viewer.html?file=${pdf.filepath}`;
  return (
    <Modal
      open
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="pdf-modal">
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h4"
          className="uppercase"
        >
          {pdf.name}
          <IconButton onClick={onClose} className="right">
            <CloseOutlinedIcon />
          </IconButton>
        </Typography>
        <Typography variant="subtitle2" className="uppercase fntXSm12 fntGrey">
          {moment(pdf.invoiceDate).format("MMMM Do, YYYY")}
        </Typography>
        <iframe
          width="100%"
          height="90%"
          src={url}
          style={{ marginTop: "15px" }}
        />
      </div>
    </Modal>
  );
};

export default PdfViewer;
