const AuthTypes = {
    SIGNUP_REQUEST: "SIGNUP_REQUEST",
    SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
    LOGIN_REQUEST: "LOGIN_REQUEST",
    LOGIN_SUCCESS: "LOGIN_SUCCESS",
    FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
    FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
    RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
    RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
    LOGOUT_REQUEST: "LOGOUT_REQUEST",
    LOGOUT_SUCCESS: "LOGOUT_SUCCESS"
};

export { AuthTypes };
