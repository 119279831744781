import { put, takeEvery } from "redux-saga/effects";
import { LoaderTypes } from "../action_types/loader_types";
import { SnackTypes } from "../action_types/snack_types";

function* setSnackbarNoError({ params }) {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    yield put({
      type: SnackTypes.OPEN_SNACKBAR_SUCCESS,
      data: params,
    });
  } catch (e) {
    console.log(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

function* closeSnackbar() {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    yield put({
      type: SnackTypes.CLOSE_SNACKBAR_SUCCESS,
    });
  } catch (e) {
    console.log(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

export default function* SnackSaga() {
  yield takeEvery(SnackTypes.OPEN_SNACKBAR, setSnackbarNoError);
  yield takeEvery(SnackTypes.CLOSE_SNACKBAR, closeSnackbar);
}
