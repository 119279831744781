import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import Input from "./Input";
import Button from "./Button";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import UploadImage from "./UploadImage";
import { TechnicianTypes } from "../redux/action_types/technician_types";
import { isEmailValid } from "./Utils";
import { CustomerTypes } from "../redux/action_types/customer_types";
import UploadDocuments from "./UploadDocuments";
import { numberRegex } from "./Utils";
import { SnackTypes } from "../redux/action_types/snack_types";

const AddModal = (props) => {
  const dispatch = useDispatch();
  const {
    openModal,
    handleCloseModal,
    title,
    type,
    isEdit,
    userObj,
    multiple = true,
  } = props;
  const [state, setState] = useState({
    firstName: isEdit ? userObj.firstName : "",
    lastName: isEdit ? userObj.lastName : "",
    email: isEdit ? userObj.email : "",
    phoneNumber: isEdit ? userObj.phoneNumber : "",
    kycdoc: [],
    profilePic: isEdit ? userObj.profilePic : "",
    profilePicObj: isEdit ? userObj.profilePic : "",
    showError: false,
    errorMessage: "",
  });

  const handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setState({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        docs: [],
        profilePic: "",
        profilePicObj: "",
        showError: false,
      });
      handleCloseModal();
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    p: 3,
  };

  const setData = (data) => {
    setState({ ...state, data, errorMessage: "" });
  };

  const handleNumberInput = (data) => {
    const phoneNumber = data.phoneNumber;

    if (numberRegex(phoneNumber)) {
      data.phoneNumber = phoneNumber;
      // setData(data);
      setState({ ...state, data, errorMessage: "" });
    }
  };

  const setProfileData = (data) => {
    data["profilePic"] = data["profilePicObj"]
      ? URL.createObjectURL(data["profilePicObj"])
      : "";
    setState({ ...state, data, errorMessage: "" });
  };

  const submitFn = () => {
    if (isEmailValid(state.email)) {
      const formData = new FormData();
      if (isEdit) {
        formData.append("id", userObj.id);
        formData.append("userType", userObj.userType);
      }
      formData.append("firstName", state.firstName);
      formData.append("lastName", state.lastName);
      formData.append("email", state.email);
      formData.append("phoneNumber", state.phoneNumber);
      formData.append("profilePic", ""); //include - 'state.profilePicObj' for imageupload
      if (type !== "customer") {
        state.kycdoc.forEach((file) => {
          formData.append("kycdoc", file);
        });
      }
      let resData;
      dispatch({
        type:
          type === "customer" && !isEdit
            ? CustomerTypes.ADD_CUSTOMER_REQUEST
            : type === "customer" && isEdit
            ? CustomerTypes.EDIT_CUSTOMER_REQUEST
            : isEdit
            ? TechnicianTypes.EDIT_TECHNICIAN_REQUEST
            : TechnicianTypes.ADD_TECHNICIAN_REQUEST,
        params: formData,
        userId: userObj ? userObj.id : null,
        callback: (data) => {
          if (data.error === false) {
            dispatch({
              type: SnackTypes.OPEN_SNACKBAR,
              message: data.message,
              error_state: false,
            });
            handleCloseModal(true);
          } else {
            dispatch({
              type: SnackTypes.OPEN_SNACKBAR,
              message: data.message,
              error_state: true,
            });
            setState({ ...state, errorMessage: data.error.message });
          }
        },
      });
    } else {
      setState({ ...state, showError: true });
    }
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title}
            <IconButton onClick={handleClose} className="right">
              <CloseOutlinedIcon />
            </IconButton>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {state.errorMessage && (
              <div className="mv1-5">
                <h4 className="tac fntSm fntInputTitle m0">
                  {state.errorMessage}
                </h4>
              </div>
            )}
            <UploadImage
              data={state}
              setData={setProfileData}
              keyName={"profilePicObj"}
              profileUrl={"profilePic"}
            />
            <Input
              type={"text"}
              icon={"name"}
              keyName={"firstName"}
              className={"small-input"}
              data={state}
              setData={setData}
              placeholder={"First name"}
            />
            <Input
              type={"text"}
              icon={"name"}
              keyName={"lastName"}
              className={"small-input"}
              data={state}
              setData={setData}
              placeholder={"Last name"}
            />
            <Input
              type={"text"}
              icon={"email"}
              keyName={"email"}
              className={"small-input"}
              data={state}
              setData={setData}
              placeholder={"Email"}
              showError={state.showError}
            />
            <Input
              type={"text"}
              icon={"phoneNumber"}
              keyName={"phoneNumber"}
              className={"small-input"}
              data={state}
              // setData={setData}
              setData={handleNumberInput}
              placeholder={"Phone Number"}
            />
            {type !== "customer" && (
              <UploadDocuments
                multiple={multiple}
                keyName={"kycdoc"}
                data={state}
                setData={setData}
              />
            )}
            <Button
              text={props.btnText}
              btnClick={submitFn}
              className="full-width primary-btn mt1"
              disabled={
                !state.firstName ||
                !state.lastName ||
                !state.email ||
                !state.phoneNumber
              }
            />
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

AddModal.propTypes = {
  openModal: PropTypes.bool,
  isEdit: PropTypes.bool,
  userObj: PropTypes.object,
  handleCloseModal: PropTypes.func,
  title: PropTypes.string,
  btnText: PropTypes.string,
  type: PropTypes.string,
};

export default AddModal;
