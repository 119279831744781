import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import AttachFileIcon from "../images/icon-attach-file.svg";

const UploadInvoice = (props) => {
    const { setData } = props;
    const inputEl = useRef(null);

    const handleFileChange = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            let fileName = files[0].name;
            const sFileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
            const fileFormats = ['pdf'];
            if (fileFormats.indexOf(sFileExtension) === -1) {
                document.getElementById('invoice-file').value = "";
                alert("Please upload pdf document.")
                return true;
            }
            setData(files);
        }
    }

    // function to trigger our input file click
    const uploadClick = () => {
        inputEl.current.click();
    };

    return (
        <Box className="mb2">
            {
                <div className="flx align-center justify-center upload-file mt2" onClick={uploadClick}>
                    <img src={AttachFileIcon} alt="attach-file" className="pr1" />
                    <a href="#" className="underline fntGreen pr1">Add File</a>
                    <span>or drop files here</span>
                </div>
            }
            <input type="file" hidden id="invoice-file" multiple onChange={handleFileChange} ref={inputEl} accept="application/pdf" />
        </Box>
    )
}

UploadInvoice.propTypes = {
    setData: PropTypes.func,
    keyName: PropTypes.string
};

export default UploadInvoice;