import { put, takeEvery } from "redux-saga/effects";
import { URI } from "../api/uri";
import { LoaderTypes } from "../action_types/loader_types";
import { TechnicianTypes } from "../action_types/technician_types";
import API from "../api";

// Get list of technicians
function* getTechnicianList({ params }) {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.TECHNICIANS_LIST}`;
    const res = yield API.post(url, params);
    yield put({
      type: TechnicianTypes.TECHNICIAN_LIST_SUCCESS,
      data: res.data.data,
    });
  } catch (e) {
    console.log(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

// Add Technician
function* addTechnician({ params, userId, callback }) {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.ADD_TECHNICIAN}`;
    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const res = yield API.post(url, params, headers);
    callback(res.data);
  } catch (e) {
    callback(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

// Edit Technician
function* editTechnician({ params, userId, callback }) {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.UPDATE_TECHNICIAN}/${userId}`;
    const headers = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    const res = yield API.post(url, params, headers);
    callback(res.data);
  } catch (e) {
    callback(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

// GET Technician details
function* getTechnicianDetails({ params }) {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.GET_TECHNICIAN}/${params}`;
    const res = yield API.get(url);
    yield put({
      type: TechnicianTypes.GET_TECHNICIAN_SUCCESS,
      data: res.data.data,
    });
  } catch (e) {
    console.log(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

// Get list of technicians with requests
function* getTechnicianListWithRequests() {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.TECHNICIANS_WITH_REQUESTS}`;
    const res = yield API.get(url);
    yield put({
      type: TechnicianTypes.TECHNICIANS_WITH_REQUESTS_SUCCESS,
      data: res.data.data,
    });
  } catch (e) {
    console.log(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

// Schedule service
function* scheduleService({ params, callback }) {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.SCHEDULE_SERVICE}`;
    const res = yield API.post(url, params);
    yield put({
      type: TechnicianTypes.SCHEDULE_SERVICE_SUCCESS,
      data: res.data.data,
    });
    callback(res.data);
  } catch (e) {
    callback(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

// Cancel service
function* cancelService({ params, callback }) {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    const url = `${process.env.REACT_APP_API_URL}/${URI.CANCEL_SERVICE}`;
    const res = yield API.post(url, params);
    yield put({
      type: TechnicianTypes.CANCEL_SERVICE_SUCCESS,
      data: res.data.data,
    });
    callback(res.data);
  } catch (e) {
    callback(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

//delete file
function* deleteFile({ fileId, cb }) {
  yield put({ type: LoaderTypes.LOADER_START });
  try {
    yield API.delete(URI.DELETE_TECH_DOC.replace("{id}", fileId));
    cb && cb();
  } catch (e) {
    console.log(e);
  }
  yield put({ type: LoaderTypes.LOADER_STOP });
}

export default function* TechnicianSaga() {
  yield takeEvery(TechnicianTypes.TECHNICIAN_LIST_REQUEST, getTechnicianList);
  yield takeEvery(TechnicianTypes.ADD_TECHNICIAN_REQUEST, addTechnician);
  yield takeEvery(TechnicianTypes.EDIT_TECHNICIAN_REQUEST, editTechnician);
  yield takeEvery(TechnicianTypes.GET_TECHNICIAN_REQUEST, getTechnicianDetails);
  yield takeEvery(
    TechnicianTypes.TECHNICIANS_WITH_REQUESTS_REQUEST,
    getTechnicianListWithRequests
  );
  yield takeEvery(TechnicianTypes.SCHEDULE_SERVICE_REQUEST, scheduleService);
  yield takeEvery(TechnicianTypes.CANCEL_SERVICE_REQUEST, cancelService);
  yield takeEvery(TechnicianTypes.DELETE_TECHNICIAN_DOCUMENT, deleteFile);
}
