import React from "react";
import PropTypes from "prop-types";
import { Select, MenuItem } from "@mui/material";

const Dropdown = (props) => {

    const { data, keyName, setData } = props;

    const handleChange = (event) => {
        data[keyName] = event.target.value;
        setData(data);
    }

    return (
        <Select
            onChange={handleChange}
            value={data[keyName]}
        >
            <MenuItem value={30}>30 Days</MenuItem>
            <MenuItem value={60}>60 Days</MenuItem>
        </Select>
    )
}

Dropdown.propTypes = {
    keyName: PropTypes.string,
    setData: PropTypes.func,
    data: PropTypes.object
};

export default Dropdown;