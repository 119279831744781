import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { Box, Typography, Modal, IconButton } from "@mui/material";
import Button from "./Button";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import IconDelete from "@mui/icons-material/Delete";
import { CustomerTypes } from "../redux/action_types/customer_types";
import UploadInvoice from "./UploadInvoice";
import { cloneDeep } from "lodash";
import { SnackTypes } from "../redux/action_types/snack_types";

const UploadInvoiceModal = (props) => {
  const dispatch = useDispatch();

  const { openModal, handleCloseModal, addressObj } = props;
  const [state, setState] = useState({
    invoicefiles: [],
    invoicefilesArr: [],
    showError: false,
    errorMessage: "",
  });

  const handleClose = (reason) => {
    if (reason !== "backdropClick") {
      setState({
        invoicefiles: [],
        invoicefilesArr: [],
        showError: false,
        errorMessage: "",
      });
      handleCloseModal();
    }
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    p: 3,
  };

  const setData = (data) => {
    const list = cloneDeep(state.invoicefiles);
    for (let single of data) {
      list.push(single);
    }
    setState({ ...state, invoicefiles: list, errorMessage: "" });
  };

  const deleteFile = (file, idx) => () => {
    state.invoicefiles.splice(idx, 1);
    setState({ ...state, invoicefiles: [...state.invoicefiles] });
  };

  const submitFn = () => {
    if (state.requestId != "" && state.invoiceDate != "") {
      const formData = new FormData();
      formData.append("addressId", addressObj.id);
      state.invoicefiles.forEach((single) => {
        formData.append("invoicefile", single);
      });
      dispatch({
        type: CustomerTypes.UPLOAD_INVOICE_REQUEST,
        params: formData,
        callback: (data) => {
          if (data.error === false) {
            
            dispatch({
              type: SnackTypes.OPEN_SNACKBAR,
              message: data.message,
              error_state: false,
            });
            handleCloseModal(true);
          } else {
            dispatch({
              type: SnackTypes.OPEN_SNACKBAR,
              message: data.message,
              error_state: true,
            });
            setState({ ...state, errorMessage: data.error.message });
          }
        },
      });
    } else {
      setState({ ...state, showError: true });
    }
  };

  return (
    <div>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            File Uploader
            <IconButton onClick={handleClose} className="right">
              <CloseOutlinedIcon />
            </IconButton>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {/* <FormControl sx={{ mb: 2, width: 400 }}>
                            <InputLabel id="demo-simple-select-label">Select Completed Request</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                label="Select Completed Request"
                                value={state.request}
                                displayEmpty
                                renderValue={(selected) => {
                                    if (selected) {
                                        return <><div>{moment(selected.target.value.completedAt).format("MMM Do, YYYY")}</div></>;
                                    }
                                }}
                                onChange={handleChange}
                            >
                                {completed && completed.length && completed.map((each) => {
                                    return <MenuItem key={each.id} value={each}  >{moment(each.completedAt).format("MMM Do, YYYY")}</MenuItem>
                                })}
                            </Select>
                        </FormControl> */}
            {/* <FormControl sx={{ mb: 1, width: 400 }}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    renderInput={(props) => <TextField {...props} />}
                                    label={<><CalendarMonthOutlinedIcon /> <div style={{ marginLeft: "31px", marginTop: "-28px" }}>Invoice Date</div></>}
                                    value={state.invoiceDate}
                                    onChange={(newValue) => {
                                        setState({ ...state, invoiceDate: newValue });
                                    }}
                                />
                            </LocalizationProvider>
                        </FormControl> */}
            {state.errorMessage && (
              <div className="mv1-5">
                <h4 className="tac fntSm fntInputTitle m0">
                  {state.errorMessage}
                </h4>
              </div>
            )}
            <UploadInvoice setData={setData} />
            <div
              className="mb2"
              style={{ maxHeight: "230px", overflowY: "scroll" }}
            >
              {state.invoicefiles.length > 0 &&
                state.invoicefiles.map((file, indx) => {
                  return (
                    <div
                      key={indx}
                      className="flx justify-between align-center"
                    >
                      <p>{file.name}</p>
                      <IconDelete color="error" onClick={deleteFile(file)} />
                    </div>
                  );
                })}
            </div>
            <Button
              text="Upload Invoice(s)"
              btnClick={submitFn}
              className="full-width primary-btn mt1"
              disabled={state.invoicefiles.length == 0}
            />
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

UploadInvoiceModal.propTypes = {
  openModal: PropTypes.bool,
  addressObj: PropTypes.object,
  handleCloseModal: PropTypes.func,
  type: PropTypes.string,
  // completed: PropTypes.array
};

export default UploadInvoiceModal;
