import { combineReducers } from "redux";

import LoaderReducer from "./loader_reducer";
import AuthReducer from "./auth_reducer";
import DashboardReducer from "./dashboard_reducer";
import TechnicianReducer from "./technician_reducer";
import CustomerReducer from "./customer_reducer";
import SnackReducer from "./snack_reducer";

const RootReducer = combineReducers({
  Loader: LoaderReducer,
  Auth: AuthReducer,
  Dashboard: DashboardReducer,
  Technician: TechnicianReducer,
  Customer: CustomerReducer,
  Snackbar: SnackReducer,
});

export default RootReducer;
