import {
  Box,
  Icon,
  IconButton,
  Modal,
  Typography,
  Grid,
  Select,
  MenuItem,
  InputLabel,
  OutlinedInput,
  FormControl,
  Button,
} from "@mui/material";
import React from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import BP_Blank from "../../images/BuildingPic.jpeg";
import Rating from "../../Shared/Rating";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import PP_Blank from "../../images/PP_Blank.png";

const ModalTechnicianDetails = (props) => {
  const { data, technicianDetails, handleCloseModal, openModal } = props;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    width: "800px",
  };
  return (
    <>
      <Modal
        open={openModal}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            className="p1 fntsm mt1 capitalize"
            variant="h6"
          >
            {data.requestStatus} Service Information
            <IconButton onClick={() => handleCloseModal()} className="right">
              <CancelOutlinedIcon />
            </IconButton>
          </Typography>
          <Grid container spacing={1.5} className="ph2">
            <Grid item xs={0} md={2} lg={5}>
              <img
                alt="Address Icon"
                className="p1"
                width="300px"
                src={BP_Blank}
              />
            </Grid>
            <Grid item xs={12} md={12} lg={7}>
              <Typography
                id="modal-modal-description"
                className="ph2 border-bottom-grey pb2"
                sx={{ mt: 2 }}
              >
                <h5>LOCATION INFORMATION</h5>
                <div
                  style={{ fontSize: "14px" }}
                  className="flx justify-between"
                >
                  <label> Service Type</label>
                  <span> {data.requestType}</span>
                </div>
                {/* <div style={{ fontSize: "14px" }} className='flx justify-between'>
                                    <label> Service Date</label>
                                    <span> {data.address.date}</span>
                                </div>
                                <div style={{ fontSize: "14px" }} className='flx justify-between'>
                                    <label> Service Time</label>
                                    <span> {data.address.time}</span>
                                </div> */}
                <div
                  style={{ fontSize: "14px" }}
                  className="flx justify-between"
                >
                  <label> Address</label>
                  <span> {data.address.address}</span>
                </div>
                <div
                  style={{ fontSize: "14px" }}
                  className="flx justify-between"
                >
                  <label> State / Zip</label>
                  <span>
                    {" "}
                    {data.address.state +
                      ", " +
                      data.address.country +
                      " " +
                      data.address.zipcode}
                  </span>
                </div>
                <div
                  style={{ fontSize: "14px" }}
                  className="flx justify-between"
                >
                  <label> Customer Name</label>
                  <span>
                    {" "}
                    {data.address.user.firstName +
                      " " +
                      data.address.user.lastName}
                  </span>
                </div>
                <div
                  style={{ fontSize: "14px" }}
                  className="flx justify-between"
                >
                  <label> Customer Phone#</label>
                  <span> {data.address.user.phoneNumber}</span>
                </div>
                <div
                  style={{ fontSize: "14px" }}
                  className="flx justify-between"
                >
                  <label> Device Number</label>
                  <span> {data.address.deviceNumber}</span>
                </div>
              </Typography>
              <Typography
                id="modal-modal-description"
                className="ph2 border-bottom-grey"
              >
                <h5>CUSTOMER NOTES</h5>
                <p style={{ fontSize: "12px" }}>{data.additionalComments}</p>
              </Typography>
              <Typography id="modal-modal-description" className="ph2 ">
                {technicianDetails.firstName ? (
                  <>
                    <h5>TECHNICIAN INFORMATION</h5>
                    <div className="flx justify-between ph2 pb2">
                      <div style={{ display: "inline-flex" }}>
                        <img
                          alt="Technician"
                          className="avatar-rounded50"
                          src={
                            technicianDetails.profilePic
                              ? technicianDetails.profilePic
                              : PP_Blank
                          }
                        />
                        <div style={{ marginLeft: "10px" }}>
                          {technicianDetails.firstName +
                            " " +
                            technicianDetails.lastName}
                        </div>
                      </div>
                      <div>
                        <Rating rate={Math.round(data.rating)} height="22" />
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <h5>REASSIGN SERVICE</h5>
                    <FormControl sx={{ m: 1, width: 280 }}>
                      <InputLabel id="demo-multiple-name-label">
                        {" "}
                        <AccountCircleOutlinedIcon /> Select New Service Person
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-name-label"
                        id="demo-multiple-name"
                        // value={age}
                        label="Age"
                        displayEmpty
                        input={<OutlinedInput label="Name" />}
                      >
                        <MenuItem value={10}>
                          Select New Service Person
                        </MenuItem>
                        <MenuItem value={20}>Technician 1</MenuItem>
                        <MenuItem value={30}>Technician 2</MenuItem>
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 280 }}>
                      <InputLabel id="demo-name-label">
                        {" "}
                        <AddCircleOutlineRoundedIcon /> May 9th, 2020 9:40am
                      </InputLabel>
                      <Select
                        labelId="demo-name-label"
                        id="demo-multipl-name"
                        // value={age}
                        label="Age"
                        displayEmpty
                        input={<OutlinedInput label="Name" />}
                      >
                        <MenuItem value={10}>
                          Select New Service Person
                        </MenuItem>
                        <MenuItem value={20}>Technician 1</MenuItem>
                        <MenuItem value={30}>Technician 2</MenuItem>
                      </Select>
                    </FormControl>

                    <Button variant="outlined" className="mb2 ml1" disabled>
                      {" "}
                      Save Changes
                    </Button>
                  </>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ModalTechnicianDetails;
