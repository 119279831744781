import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material";
import Logo from "../../../images/pumperpal-logo-light.svg";
import Input from "../../../Shared/Input";
import Button from "../../../Shared/Button";
import { AuthTypes } from "../../../redux/action_types/auth_types";

const ResetPassword = () => {
    const { id } = useParams();
    const [state, setState] = useState({
        resetPasswordForm: {
            password: '',
            confirmPassword: '',
            reset_key: id
        },
        resetPasswordSuccess: false,
        showError: false
    });

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const setData = (data) => {
        setState({ ...state, resetPasswordForm: data, showError: false });
    }

    const submitFn = (e) => {
        e.preventDefault();
        const { resetPasswordForm } = state;
        if (resetPasswordForm.password === resetPasswordForm.confirmPassword) {
            dispatch({
                type: AuthTypes.RESET_PASSWORD_REQUEST,
                payload: {
                    reset_key: resetPasswordForm.reset_key,
                    password: resetPasswordForm.password
                },
                callback: (error) => {
                    if (error) {
                        setState({ ...state, showError: true })
                        return;
                    }
                    setState({ ...state, resetPasswordSuccess: true });
                },
            });
        } else {
            setState({ ...state, showError: true });
        }
    }

    const redirectToLogin = () => {
        navigate("/Login")
    }

    const { resetPasswordForm, showError } = state;
    return (
        <Box className="global-auth-container">
            <div className="auth-side-container">
                <div className="tac pv3">
                    <img src={Logo} alt="logo" />
                </div>

                <div className="mt5 tac ph5">
                    <h2 className="fntWhite mb5 letter-spacing-lg fntXLg">RESET PASSWORD</h2>
                    {
                        showError &&
                        <div className="mv1-5">
                            <h4 className="tac fntSm fntInputTitle m0">Incorrect password.</h4>
                            <h4 className="tac fntSm fntInputTitle m0">Please try again.</h4>
                        </div>
                    }
                    {
                        state.resetPasswordSuccess ?
                            <div>
                                <h4 className="tac fntSm fntInputTitle m0">Your password has been reset!</h4>
                                <Button text={"LOGIN"} btnClick={redirectToLogin} className="full-width primary-btn letter-spacing-lg mt2" />
                            </div>
                            :
                            <div>
                                <div className="mv1-5">
                                    <h4 className="tac fntSm fntInputTitle m0">Enter your new password twice below.</h4>
                                </div>
                                <form className="mt2">
                                    <Input type={"password"} icon={"password"} keyName={'password'} data={resetPasswordForm} setData={setData} placeholder={"Password"} showError={showError} />
                                    <Input type={"password"} icon={"password"} keyName={'confirmPassword'} data={resetPasswordForm} setData={setData} placeholder={"Confirm Password"} showError={showError} />
                                    <Button text={"RESET PASSWORD"} disabled={!resetPasswordForm.password || !resetPasswordForm.confirmPassword} btnClick={submitFn} className="full-width primary-btn letter-spacing-lg mt2" />
                                </form>
                            </div>

                    }

                </div>
                <div className="tac link-footer full-width">
                    <a href="/login" className="underline fntWhite fntMd">Back to Login</a>
                </div>

            </div>
        </Box>
    )
}

export default ResetPassword;