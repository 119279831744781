import React from "react";
import PropTypes from "prop-types";
import StarIcon from "../images/icon-star.svg";
import StarWhiteIcon from "../images/icon-star-white.svg";

const Rating = (props) => {
  const { rate, height } = props;

  return (
    <>
      {rate >= 1 ? (
        [...Array(5)].map((star, idx) => {
          return (
            <img
              src={idx < rate ? StarIcon : StarWhiteIcon}
              alt="start"
              height={height}
              className="pr0-2"
              key={idx}
            />
          );
        })
      ) : (
        <div className="fntMd">No Ratings To Display</div>
      )}
    </>
  );
};

Rating.propTypes = {
  rate: PropTypes.number,
};

export default Rating;
