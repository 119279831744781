import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import PrivateRoute from "./private_routes";
import PublicRoute from "./public_routes";
import Loader from "./Shared/Loader";
import Login from "./Components/Auth/Login/Login";
import ForgotPassword from "./Components/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./Components/Auth/ResetPassword/ResetPassword";
import Dashboard from "./Components/Dashboard/Dashboard";
import Customers from "./Components/Customers/Customers";
import Technician from "./Components/Technician/Technician";
import Profile from "./Components/Profile/Profile";
import CustomerDetails from "./Components/CustomerDetails/CustomerDetails";
import TechnicianDetails from "./Components/TechnicianDetails/TechnicianDetails";
import PositionedSnackBar from "./Components/PositionedSnackBar/PositionedSnackBar";

export default function AppRoutes() {
  const loading = useSelector((state) => state.Loader.loading);
  return (
    <div>
      <BrowserRouter>
        <Loader loading={loading} />
        <Routes>
          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route path="/resetPassword/:id" element={<ResetPassword />} />
          <Route
            path="/Dashboard"
            element={
              <PrivateRoute>
                {" "}
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/Customer"
            element={
              <PrivateRoute>
                {" "}
                <Customers />
              </PrivateRoute>
            }
          />
          <Route
            path="/Customer/:id"
            element={
              <PrivateRoute>
                {" "}
                <CustomerDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/Technician"
            element={
              <PrivateRoute>
                {" "}
                <Technician />
              </PrivateRoute>
            }
          />
          <Route
            path="/Technician/:id"
            element={
              <PrivateRoute>
                {" "}
                <TechnicianDetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/Profile"
            element={
              <PrivateRoute>
                {" "}
                <Profile />
              </PrivateRoute>
            }
          />
          <Route path="/" element={<Navigate replace to="/login" />} />
          <Route path="*" element={<Navigate replace to="/login" />} />
        </Routes>
      </BrowserRouter>
      <PositionedSnackBar />
    </div>
  );
}
