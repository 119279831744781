import React from "react";
import PropTypes from "prop-types";
import { Checkbox as CBox } from "@mui/material";

const Checkbox = (props) => {
    const { isChecked, keyName, listName, setCheckBoxData } = props;
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

    const handleChecboxChange = (e) => {
        const value = e.target.checked;
        setCheckBoxData(value, keyName, listName)
    }

    return (
        <CBox {...label} checked={isChecked} onChange={handleChecboxChange} />
    )
}

Checkbox.propTypes = {
    keyName: PropTypes.any,
    listName: PropTypes.string,
    isChecked: PropTypes.bool,
    setCheckBoxData: PropTypes.func,
};

export default Checkbox;