import React, { useRef } from "react";
import PropTypes from "prop-types";
import { Button } from "@mui/material";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import { cloneDeep, map } from "lodash";
import { CloseOutlined } from "@mui/icons-material";

const UploadDocuments = (props) => {
  const { data, keyName, setData, multiple = true } = props;
  const inputEl = useRef(null);

  const handleFileChange = (event) => {
    const files = event.target.files;
    const supportedFiles = [];
    let nonPdfFiles = false;
    map([...files], (file) => {
      let fileName = file.name;
      const sFileExtension = fileName
        .split(".")
        [fileName.split(".").length - 1].toLowerCase();
      const fileFormats = ["pdf"];
      if (fileFormats.indexOf(sFileExtension) === -1) {
        nonPdfFiles = true;
      } else {
        supportedFiles.push(file);
      }
    });
    if (nonPdfFiles) {
      alert("Please upload pdf documents");
    }
    let list = cloneDeep(data[keyName]);
    list = list.concat(supportedFiles);
    data[keyName] = list;
    setData(data);
  };

  const uploadClick = () => {
    inputEl.current.click();
  };

  const removeFile = (index) => {
    const list = cloneDeep(data[keyName]);
    list.splice(index, 1);
    data[keyName] = list;
    setData(data);
  };

  return (
    <React.Fragment>
      {data[keyName] &&
        data[keyName].map((file, index) => {
          return (
            <div
              key={index}
              className="pv1 ph1 mb1 flx justify-between fntGray fntMd borderGrey"
            >
              <span className="flx align-center">
                <UploadFileOutlinedIcon className="pr0-5" /> {file.name}
              </span>
              <span
                className="flx align-center cursPoint"
                onClick={() => removeFile(index)}
              >
                <CloseOutlined fontSize="13" />
              </span>
            </div>
          );
        })}
      {(multiple || !data[keyName].length) && (
        <Button
          className="upload-doc-btn mb2 mt1"
          onClick={uploadClick}
          disabled={multiple ? false : !!data[keyName].length}
        >
          <UploadFileOutlinedIcon className="pr0-5" /> UPLOAD DOCUMEN
          {multiple ? "TS" : "T"}
        </Button>
      )}
      <input
        type="file"
        hidden
        id="upload-doc"
        multiple={multiple}
        onChange={handleFileChange}
        ref={inputEl}
        accept="application/pdf"
      />
    </React.Fragment>
  );
};

UploadDocuments.propTypes = {
  data: PropTypes.object,
  keyName: PropTypes.string,
  setData: PropTypes.func,
};

export default UploadDocuments;
