import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import AppRoutes from './routes';
import { Provider } from "react-redux";
import Store from "./redux/store";

ReactDOM
  .createRoot(document.getElementById('root'))
  .render(
    <Provider store={Store}>
      <AppRoutes />
    </Provider>
  );


