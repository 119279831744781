import { useDispatch } from "react-redux";
import {
  Box,
  IconButton,
  Modal,
  Typography,
  FormControl,
  Button,
} from "@mui/material";
import React from "react";
import moment from "moment";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { TechnicianTypes } from "../../redux/action_types/technician_types";
import { EditOutlined } from "@mui/icons-material";
import { SnackTypes } from "../../redux/action_types/snack_types";

const ModalScheduleService = (props) => {
  const dispatch = useDispatch();
  const {
    data,
    handleCloseModal,
    handleCloseOnCancel,
    openModal,
    rescheduleService,
    updateRequestStatus,
  } = props;
  const [clickedCancel, setClickedCancel] = React.useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    padding: "15px",
  };

  const handleCancelAppointment = async () => {
    if (!clickedCancel) {
      setClickedCancel((clickedCancel) => !clickedCancel);
      return;
    }
    const paramsObj = {
      addressId: data.addressId,
      requestId: data.requestId,
    };
    updateRequestStatus("CANCELLED");
    const rescol = await new Promise((resolve) => {
      dispatch({
        type: TechnicianTypes.CANCEL_SERVICE_REQUEST,
        params: paramsObj,
        callback: (res) => {
          if (res.error === false) {
            dispatch({
              type: SnackTypes.OPEN_SNACKBAR,
              message: res.message,
              error_state: false,
            });
            handleCloseOnCancel("CANCELLED");
          } else {
            dispatch({
              type: SnackTypes.OPEN_SNACKBAR,
              message: res.message,
              error_state: true,
            });
          }
        },
      });
      resolve();
    });
  };

  const editScheduleService = () => {
    rescheduleService();
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={() => handleCloseModal()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            className="p1 fntMd mt1"
            variant="h6"
          >
            Edit Service
            <IconButton
              onClick={() => handleCloseModal()}
              className="modalClose"
            >
              <CancelOutlinedIcon />
            </IconButton>
          </Typography>
          <Typography id="modal-modal-description" className="p1">
            <>
              {data.technician.profilePic ? (
                <img
                  src={data.technician.profilePic}
                  alt="profile-pic"
                  className="mr1 profile-pic-table vam"
                />
              ) : (
                <AccountCircleOutlinedIcon className="mr1 vam" />
              )}
              {data.technician.firstName + " " + data.technician.lastName}
            </>
          </Typography>
          <Typography id="modal-modal-description" className="p1 mb3 flx">
            <>
              <CalendarMonthOutlinedIcon />{" "}
              <div className="mr1">
                {moment(data.scheduledTime).format("MMM Do, YYYY h:ma")}
              </div>
              <EditOutlined
                className="mr1 vam cursPoint"
                onClick={editScheduleService}
              />
            </>
          </Typography>
          <Typography className="p1 bt2">
            <FormControl sx={{ m: 1, width: 360 }}>
              <h5 className="mb1 mt0">CUSTOMER NOTES</h5>
              <p
                className="mb1-5 mt0 customerNotes"
                style={{ fontSize: "12px", minHeight: "150px" }}
              >
                {data.customerNotes}
              </p>
            </FormControl>
          </Typography>
          {clickedCancel && (
            <div className="mh2">
              <Typography className="p2 cancelNote">
                Cancelling the appointment will send a notification <br />
                for the customer. Are you sure you want to cancel the
                appointment?
              </Typography>
            </div>
          )}
          <Typography className="tac">
            <FormControl sx={{ m: 1, width: 360 }}>
              <Button
                variant="outlined"
                className={`${clickedCancel ? "clickedCancel" : "cancelBtn"}`}
                onClick={(e) => handleCancelAppointment()}
              >
                CANCEL APPOINTMENT
              </Button>
            </FormControl>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default ModalScheduleService;
