import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TechnicianTypes } from "../../redux/action_types/technician_types";
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import * as UI from "@mui/material";
import * as Icon from "@mui/icons-material";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import Header from "../Header/Header";
import PP_Blank from "../../images/PP_Blank.png";
import Rating from "../../Shared/Rating";
import Button from "../../Shared/Button";
import AddModal from "../../Shared/AddModal";
import { convertDate, getFullName } from "../../Shared/Utils";
import ModalTechnicianDetails from "./Modal-TechnicianDetails";
import moment from "moment";
import { map } from "lodash";
import PdfViewer from "../../Shared/PdfViewer";

/**
 * Returning required redux state data
 * @param {Object} state - Redux state
 */
const getReduxData = (state) => {
  return {
    TechnicianData: state.Technician,
  };
};
const TechnicianDetails = () => {
  const dispatch = useDispatch();
  const data = useSelector(getReduxData);
  const urlParams = useParams();
  const [state, setState] = useState({
    openModal: false,
    fileUploadError: "",
    toggleMenu: "scheduled",
    openDetailModal: false,
    techData: {},
    pdfFile: "",
  });
  const handleCloseModal = (val) => {
    setState({ ...state, openModal: false, openDetailModal: false });
    if (val) {
      getTechnicianDetails();
    }
  };
  const openDetailModal = (techData) => {
    console.log("on click triggered");
    setState({ ...state, openDetailModal: true, techData: techData });
    console.log(techData);
  };

  const deleteFile = (file) => {
    if (window.confirm(`Are you sure you want to delete ${file.name}?`)) {
      dispatch({
        type: TechnicianTypes.DELETE_TECHNICIAN_DOCUMENT,
        fileId: file.id,
        cb: getTechnicianDetails,
      });
    }
  };

  const openPdf = (file) => () => {
    setState({ ...state, pdfFile: file });
  };

  useEffect(() => {
    getTechnicianDetails();
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch({
        type: TechnicianTypes.GET_TECHNICIAN_SUCCESS,
        data: {
          technicianDetails: {},
          scheduled: [],
          requested: [],
          completed: [],
          invoices: [],
        },
      });
    };
  }, []);

  const getTechnicianDetails = () => {
    dispatch({
      type: TechnicianTypes.GET_TECHNICIAN_REQUEST,
      params: urlParams.id,
    });
  };

  const handleTogleChange = (e) => {
    setState({ ...state, toggleMenu: [e.target.value] });
  };

  const editTechnicianFn = () => {
    setState({ ...state, openModal: true });
  };

  const { technicianDetails, scheduled, completed } =
    data.TechnicianData.technicianDetails;
  return (
    <Container className="main">
      <Header />
      <Box className="ph3">
        <Grid container spacing={1.5}>
          <Grid item xs={12} md={12} lg={5}>
            <Link href="/Technician" className="mb1" underline="hover">
              {" "}
              <ArrowBackIosNewIcon className="fntXXSm fntGreen" />{" "}
              <span className="fntSm fntGreen">TECHNICIANS</span>{" "}
            </Link>
            <div className=" border-bottom-grey p1">
              <div>
                <img
                  alt="Technician"
                  className="profile-image p1"
                  width="100" height="100"
                  src={
                    technicianDetails.profilePic
                      ? technicianDetails.profilePic
                      : PP_Blank
                  }
                />
              </div>
              <Typography className="title fntXXLg">
                {getFullName(technicianDetails)}
              </Typography>
              <div className="flx">
                <Rating
                  rate={Math.round(technicianDetails.rating)}
                  height="22"
                />
                {technicianDetails.rating > 0 && <div className="fntLg ml1 flx">
                  {technicianDetails.rating} Average
                </div>}
              </div>
            </div>
            <div className=" border-bottom-grey p1">
              <Typography className="fntGrey ph0-5 fntSm">
                TECHNICIAN INFORMATION{" "}
              </Typography>
              <div className="justify-between flx mr5 fntSm pt0-5">
                <div>Email </div>
                <div>{technicianDetails.email}</div>
              </div>
              <div className="justify-between flx mr5 fntSm pt0-5">
                <div>Phone Number</div>
                <div>{technicianDetails.phoneNumber}</div>
              </div>
              <div className="justify-between flx mr5 fntSm pt0-5">
                <div>Member Since</div>
                <div>{convertDate(technicianDetails.createdAt)}</div>
              </div>
              <Button
                text={"EDIT INFORMATION"}
                btnClick={editTechnicianFn}
                className="primary-btn mt1-5"
                icon={<Icon.EditOutlined className="fntLg mr0-5" />}
              />
            </div>
            <div className=" border-bottom-grey p1 ">
              <Typography className="fntGrey ph0-5 pv1 fntSm">
                TECHNICIAN DOCUMENTS{" "}
              </Typography>
              {map(technicianDetails.techfiles, (file, indx) => {
                return (
                  <div
                    className="full-border-grey flx  pv0-3 mb0-5 align-center cursPoint"
                    key={indx}
                  >
                    <div
                      className="taj border-right-grey p0-5 flx align-center width-92p"
                      onClick={openPdf(file)}
                    >
                      <Icon.Description className="fntLg ml0-5" />
                      <div className="fntMd mh3">{file.name}</div>
                      {/* <Icon.RemoveRedEye className="fntLg mr0-5" />{" "} */}
                    </div>
                    <div className="width-8p">
                      {" "}
                      <Icon.Delete
                        className="fntLg ph1 pv0-5"
                        onClick={() => deleteFile(file)}
                      />
                    </div>
                  </div>
                );
              })}
              {/* <Button text={'UPLOAD A FILE'} btnClick={fileUpload} className="primary-btn mv1-5" icon={<Icon.Add className='fntLg mr0-5' />} />
                            <input type="file" hidden id="profile-pic" onChange={handleFileChange} ref={addFile} /> */}
            </div>
          </Grid>
          <Grid item xs={12} md={12} lg={7} className="p3">
            <div className="full-border-grey  ph1 pv0-5 l0">
              <UI.ToggleButtonGroup
                value={state.toggleMenu}
                exclusive
                onChange={handleTogleChange}
                fullWidth
                size="small"
              >
                <UI.ToggleButton value="scheduled">Scheduled</UI.ToggleButton>
                <UI.ToggleButton value="completedServices">
                  Completed Services
                </UI.ToggleButton>
              </UI.ToggleButtonGroup>
            </div>
            {state.toggleMenu == "scheduled"
              ? scheduled.map((each, indx) => {
                return (
                  <div
                    className="full-border-grey justify-between flx  pv0-3 mv0-5"
                    key={indx}
                    onClick={() => {
                      openDetailModal(each);
                    }}
                  >
                    <div>
                      <Icon.Event className="fntXLg pv0-5 ph1" />{" "}
                      <p className="fntMd mh1 address">
                        {each.address.address}
                      </p>
                    </div>
                    <div className="pt0-8">
                      {" "}
                      <span className="fntMd mh3 mv1 pt1">
                        {moment(each.scheduledTime).format("MMM Do, YYYY")}
                      </span>{" "}
                    </div>
                    <div className="pt0-8">
                      {" "}
                      <span className="fntMd mh3 pt1">
                        {moment(each.scheduledTime).format("hh:mm A")}
                      </span>
                    </div>
                    <div className="pt0-8">
                      {" "}
                      <Icon.RemoveRedEye className="fntXLg pv0-5 ph1" />
                    </div>
                  </div>
                );
              })
              : completed.map((each, indx) => {
                return (
                  <div
                    className="full-border-grey justify-between flx  pv0-3 mv0-5"
                    key={indx}
                    onClick={() => {
                      openDetailModal(each);
                    }}
                  >
                    <div className="width-30p">
                      <Icon.Check className="fntXLg  pv0-5 ph1" />
                      <p className="fntMd mh1 address">
                        {each.address.address}
                      </p>
                    </div>
                    <div className="pt0-8 width-30p">
                      {" "}
                      <span
                        className="fntMd mh3 mv1"
                        style={{ whiteSpace: "nowrap" }}
                      >
                        {moment(each.completedAt).format("MMM Do, YYYY")}
                      </span>{" "}
                    </div>
                    <div
                      className="pt0-8 width-30p"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {" "}
                      <Rating rate={Math.round(each.rating)} height="22" />
                    </div>
                    <div className="pt0-8 width-10p">
                      {" "}
                      <Icon.RemoveRedEye className="fntXLg pv0-5 ph1" />
                    </div>
                  </div>
                );
              })}
          </Grid>
        </Grid>
      </Box>
      {state.pdfFile && (
        <PdfViewer
          pdf={state.pdfFile}
          onClose={() => setState({ ...state, pdfFile: "" })}
        />
      )}
      {state.openModal && (
        <AddModal
          openModal={state.openModal}
          handleCloseModal={handleCloseModal}
          isEdit={true}
          multiple={true}
          userObj={technicianDetails}
          title={"Edit Technician information"}
          btnText={"SAVE CHANGES"}
        />
      )}
      {state.openDetailModal && (
        <ModalTechnicianDetails
          data={state.techData}
          technicianDetails={technicianDetails}
          openModal={state.openDetailModal}
          handleCloseModal={handleCloseModal}
        />
      )}
    </Container>
  );
};

export default TechnicianDetails;
