import React from "react";
import PropTypes from "prop-types";
import { TextField } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

const Search = (props) => {
    const { data, keyName, setSearchData } = props;

    const handleInputChange = (e) => {
        data[keyName] = e.target.value;
        setSearchData(data);
    }

    return (
        <TextField
            type="text"
            className={`mb2 full-width search-input`}
            placeholder="Enter Search"
            autoComplete="off"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchOutlinedIcon />
                    </InputAdornment>
                )
            }}
            value={data[keyName]}
            onChange={(e) => handleInputChange(e)}
        />
    )
}

Search.propTypes = {
    data: PropTypes.object,
    className: PropTypes.string,
    keyName: PropTypes.string,
    setSearchData: PropTypes.func
};

export default Search;