import { SnackTypes } from "../action_types/snack_types";

const initialState = {
  open: false,
  vertical: "top",
  horizontal: "right",
  message: "",
  error_state: false,
  auto_hide_duration: 3000,
};

export default function snackReducer(state = initialState, action = null) {
  if (!state) {
    state = initialState;
  }
  switch (action.type) {
    case SnackTypes.OPEN_SNACKBAR: {
      return {
        ...state,
        open: true,
        message: action.message,
        error_state: action.error_state,
      };
    }
    case SnackTypes.CLOSE_SNACKBAR: {
      return {
        ...state,
        open: false,
        message: "",
        error_state: false,
      };
    }
    default:
      return state;
  }
}
